export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Prove",
    opciones: [
      "Demostrar", 
      "Evidenciar", 
      "Justificar", 
      "Comprobar", 
      "Verificar", 
      "Confirmar",
    ],
    respuestasCorrectas: ["Demostrar"],
    fonetica: ["pruv"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Pull",
    opciones: [
      "Jalar", 
      "Tirar", 
      "Arrastrar", 
      "Atraer", 
      "Traer", 
      "Halar",
    ],
    respuestasCorrectas: ["Jalar"],
    fonetica: ["pʊl"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Push",
    opciones: [
      "Empujar", 
      "Impulsar", 
      "Presionar", 
      "Mover", 
      "Desplazar", 
      "Forzar",
    ],
    respuestasCorrectas: ["Empujar"],
    fonetica: ["pʊʃ"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Raise",
    opciones: [
      "Elevar", 
      "Levantar", 
      "Aumentar", 
      "Subir", 
      "Ascender", 
      "Alzar",
    ],
    respuestasCorrectas: ["Elevar"],
    fonetica: ["reɪz"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Reach",
    opciones: [
      "Alcanzar", 
      "Llegar", 
      "Extenderse", 
      "Lograr", 
      "Obtener", 
      "Tocar",
    ],
    respuestasCorrectas: ["Alcanzar"],
    fonetica: ["riʧ"],
  },
  {
    id: 6,
    texto: '',
    palabra: "React",
    opciones: [
      "Reaccionar", 
      "Responder", 
      "Actuar", 
      "Replicar", 
      "Contestar", 
      "Contestar",
    ],
    respuestasCorrectas: ["Reaccionar"],
    fonetica: ["riˈækt"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Receive",
    opciones: [
      "Recibir", 
      "Aceptar", 
      "Tomar", 
      "Obtener", 
      "Adquirir", 
      "Captar",
    ],
    respuestasCorrectas: ["Recibir"],
    fonetica: ["rəˈsiv"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Recognize",
    opciones: [
      "Reconocer", 
      "Identificar", 
      "Distinguir", 
      "Aceptar", 
      "Recordar", 
      "Descubrir",
    ],
    respuestasCorrectas: ["Reconocer"],
    fonetica: ["ˈrɛkəɡˌnaɪz"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Reduce",
    opciones: [
      "Reducir", 
      "Disminuir", 
      "Bajar", 
      "Minimizar", 
      "Atenuar", 
      "Mermar",
    ],
    respuestasCorrectas: ["Reducir"],
    fonetica: ["rəˈdus"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Regret",
    opciones: [
      "Lamentar", 
      "Arrepentirse", 
      "Sentir", 
      "Apesadumbrarse", 
      "Remorderse", 
      "Penar",
    ],
    respuestasCorrectas: ["Lamentar"],
    fonetica: ["rəˈɡrɛt"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Remember",
    opciones: [
      "Recordar", 
      "Rememorar", 
      "Evocar", 
      "Revivir", 
      "Remembrar", 
      "Traer a la memoria",
    ],
    respuestasCorrectas: ["Recordar"],
    fonetica: ["rɪˈmɛmbər"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Rent",
    opciones: [
      "Rentar", 
      "Alquilar", 
      "Arrendar", 
      "Subarrendar", 
      "Tomar en alquiler", 
      "Tomar en arriendo",
    ],
    respuestasCorrectas: ["Rentar"],
    fonetica: ["rɛnt"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Report",
    opciones: [
      "Reportar", 
      "Informar", 
      "Comunicar", 
      "Notificar", 
      "Anunciar", 
      "Dar parte",
    ],
    respuestasCorrectas: ["Reportar"],
    fonetica: ["rɪˈpɔrt"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Represent",
    opciones: [
      "Representar", 
      "Simbolizar", 
      "Encarnar", 
      "Personificar", 
      "Manifestar", 
      "Exponer",
    ],
    respuestasCorrectas: ["Representar"],
    fonetica: ["ˌrɛprəˈzɛnt"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Rescue",
    opciones: [
      "Rescatar", 
      "Salvar", 
      "Liberar", 
      "Socorrer", 
      "Auxiliar", 
      "Recuperar",
    ],
    respuestasCorrectas: ["Rescatar"],
    fonetica: ["ˈrɛskju"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Rest",
    opciones: [
      "Descansar", 
      "Reposar", 
      "Relajarse", 
      "Reponer fuerzas", 
      "Recuperarse", 
      "Tomar un descanso",
    ],
    respuestasCorrectas: ["Descansar"],
    fonetica: ["rɛst"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Return",
    opciones: [
      "Regresar", 
      "Volver", 
      "Retornar", 
      "Devolver", 
      "Retornar", 
      "Reingresar",
    ],
    respuestasCorrectas: ["Regresar"],
    fonetica: ["rɪˈtɜrn"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Rob",
    opciones: [
      "Robar", 
      "Sustraer", 
      "Despojar", 
      "Hurtar", 
      "Atracar", 
      "Asaltar",
    ],
    respuestasCorrectas: ["Robar"],
    fonetica: ["rɑb"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Rub",
    opciones: [
      "Frotar", 
      "Restregar", 
      "Masajear", 
      "Rozar", 
      "Raspar", 
      "Lijar",
    ],
    respuestasCorrectas: ["Frotar"],
    fonetica: ["rʌb"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Save",
    opciones: [
      "Salvar/Ahorrar", 
      "Guardar", 
      "Rescatar", 
      "Economizar", 
      "Preservar", 
      "Conservar",
    ],
    respuestasCorrectas: ["Salvar/Ahorrar"],
    fonetica: ["seɪv"],
  },
];