export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Technical Support Technician",
    opciones: [
      "Técnico en soporte técnico", 
      "Diseñador gráfico", 
      "Arquitecto", 
      "Escritor", 
      "Ilustrador", 
      "Ingeniero",
    ],
    respuestasCorrectas: ["Técnico en soporte técnico"],
    fonetica: ["ˈtɛknɪkəl səˈpɔrt tɛkˈnɪʃən"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Graphic Designer",
    opciones: [
      "Diseñador gráfico", 
      "Técnico en soporte técnico", 
      "Arquitecto", 
      "Escritor", 
      "Ilustrador", 
      "Ingeniero",
    ],
    respuestasCorrectas: ["Diseñador gráfico"],
    fonetica: ["ˈɡræfɪk dɪˈzaɪnər"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Architect",
    opciones: [
      "Arquitecto", 
      "Diseñador gráfico", 
      "Técnico en soporte técnico", 
      "Escritor", 
      "Ilustrador", 
      "Ingeniero",
    ],
    respuestasCorrectas: ["Arquitecto"],
    fonetica: ["ˈɑrkəˌtɛkt"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Writer",
    opciones: [
      "Escritor", 
      "Diseñador gráfico", 
      "Arquitecto", 
      "Técnico en soporte técnico", 
      "Ilustrador", 
      "Ingeniero",
    ],
    respuestasCorrectas: ["Escritor"],
    fonetica: ["ˈraɪtər"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Illustrator",
    opciones: [
      "Ilustrador", 
      "Diseñador gráfico", 
      "Arquitecto", 
      "Escritor", 
      "Técnico en soporte técnico", 
      "Ingeniero",
    ],
    respuestasCorrectas: ["Ilustrador"],
    fonetica: ["ˈɪləˌstreɪtər"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Film Producer",
    opciones: [
      "Productor de cine", 
      "Director", 
      "Actor", 
      "Guionista", 
      "Diseñador de producción", 
      "Editor",
    ],
    respuestasCorrectas: ["Productor de cine"],
    fonetica: ["fɪlm prəˈdusər"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Actor",
    opciones: [
      "Actor", 
      "Productor de cine", 
      "Músico", 
      "Fotógrafo", 
      "Chef", 
      "Arquitecto",
    ],
    respuestasCorrectas: ["Actor"],
    fonetica: ["ˈæktər"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Musician",
    opciones: [
      "Músico", 
      "Actor", 
      "Productor de cine", 
      "Fotógrafo", 
      "Chef", 
      "Escritor",
    ],
    respuestasCorrectas: ["Músico"],
    fonetica: ["mjuˈzɪʃən"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Photographer",
    opciones: [
      "Fotógrafo", 
      "Músico", 
      "Actor", 
      "Productor de cine", 
      "Chef", 
      "Diseñador gráfico",
    ],
    respuestasCorrectas: ["Fotógrafo"],
    fonetica: ["fəˈtɑɡrəfər"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Chef",
    opciones: [
      "Chef", 
      "Fotógrafo", 
      "Músico", 
      "Actor", 
      "Productor de cine", 
      "Arquitecto",
    ],
    respuestasCorrectas: ["Chef"],
    fonetica: ["ʃɛf"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Accountant",
    opciones: [
      "Contador", 
      "Economista", 
      "Auditor", 
      "Analista financiero", 
      "Consultor empresarial", 
      "Abogado",
    ],
    respuestasCorrectas: ["Contador"],
    fonetica: ["əˈkaʊntənt"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Economist",
    opciones: [
      "Economista", 
      "Contador", 
      "Analista financiero", 
      "Consultor empresarial", 
      "Auditor", 
      "Abogado",
    ],
    respuestasCorrectas: ["Economista"],
    fonetica: ["ɪˈkɑnəmɪst"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Human Resources Specialist",
    opciones: [
      "Especialista en recursos humanos", 
      "Contador", 
      "Economista", 
      "Analista financiero", 
      "Consultor empresarial", 
      "Abogado",
    ],
    respuestasCorrectas: ["Especialista en recursos humanos"],
    fonetica: ["ˈhjumən ˈrisɔrsɪz ˈspɛʃələst"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Financial Analyst",
    opciones: [
      "Analista financiero", 
      "Economista", 
      "Contador", 
      "Consultor empresarial", 
      "Auditor", 
      "Abogado",
    ],
    respuestasCorrectas: ["Analista financiero"],
    fonetica: ["fəˈnænʃəl ˈænələst"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Business Consultant",
    opciones: [
      "Consultor empresarial", 
      "Contador", 
      "Economista", 
      "Analista financiero", 
      "Auditor", 
      "Abogado",
    ],
    respuestasCorrectas: ["Consultor empresarial"],
    fonetica: ["ˈbɪznəs kənˈsʌltənt"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Project Manager",
    opciones: [
      "Gerente de proyectos", 
      "Jefe de equipo", 
      "Director de operaciones", 
      "Supervisor", 
      "Gerente de producto", 
      "Coordinador",
    ],
    respuestasCorrectas: ["Gerente de proyectos"],
    fonetica: ["ˈprɑʤɛkt ˈmænəʤər"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Lawyer",
    opciones: [
      "Abogado", 
      "Fiscal", 
      "Juez", 
      "Notario", 
      "Procurador", 
      "Defensor",
    ],
    respuestasCorrectas: ["Abogado"],
    fonetica: ["ˈlɔjər"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Biologist",
    opciones: [
      "Biólogo", 
      "Químico", 
      "Físico", 
      "Botánico", 
      "Zoologo", 
      "Microbiólogo",
    ],
    respuestasCorrectas: ["Biólogo"],
    fonetica: ["baɪˈɑləʤɪst"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Geologist",
    opciones: [
      "Geólogo", 
      "Geógrafo", 
      "Cartógrafo", 
      "Hidrólogo", 
      "Sismólogo", 
      "Vulcanólogo",
    ],
    respuestasCorrectas: ["Geólogo"],
    fonetica: ["ʤiˈɑləʤəst"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Environmental Engineer",
    opciones: [
      "Ingeniero ambiental", 
      "Ingeniero civil", 
      "Ingeniero industrial", 
      "Ingeniero químico", 
      "Ingeniero agrónomo", 
      "Ingeniero mecánico",
    ],
    respuestasCorrectas: ["Ingeniero ambiental"],
    fonetica: ["ɪnˌvaɪrənˈmɛntəl ˈɛnʤəˈnɪr"],
  },
];