export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Set",
    opciones: [
      "Poner/Colocar", 
      "Situar", 
      "Establecer", 
      "Fijar", 
      "Disponer", 
      "Ajustar",
    ],
    respuestasCorrectas: ["Poner/Colocar"],
    fonetica: ["sɛt"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Sew",
    opciones: [
      "Coser", 
      "Tejer", 
      "Remendar", 
      "Pespuntear", 
      "Hilvanar", 
      "Zurcir",
    ],
    respuestasCorrectas: ["Coser"],
    fonetica: ["soʊ"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Shake",
    opciones: [
      "Sacudir", 
      "Agitar", 
      "Meneear", 
      "Conmover", 
      "Vibrar", 
      "Estremecer",
    ],
    respuestasCorrectas: ["Sacudir"],
    fonetica: ["ʃeɪk"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Shine",
    opciones: [
      "Brillar", 
      "Resplandecer", 
      "Lucir", 
      "Destellar", 
      "Relumbrar", 
      "Iluminar",
    ],
    respuestasCorrectas: ["Brillar"],
    fonetica: ["ʃaɪn"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Shoot",
    opciones: [
      "Disparar", 
      "Tirar", 
      "Lanzar", 
      "Apropiar", 
      "Ejectuar", 
      "Proyectar",
    ],
    respuestasCorrectas: ["Disparar"],
    fonetica: ["ʃut"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Show",
    opciones: [
      "Mostrar", 
      "Exhibir", 
      "Enseñar", 
      "Revelar", 
      "Presentar", 
      "Demostrar",
    ],
    respuestasCorrectas: ["Mostrar"],
    fonetica: ["ʃoʊ"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Shrink",
    opciones: [
      "Encoger", 
      "Reducir", 
      "Disminuir", 
      "Replegar", 
      "Contracción", 
      "Mermar",
    ],
    respuestasCorrectas: ["Encoger"],
    fonetica: ["ʃrɪŋk"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Sing",
    opciones: [
      "Cantar", 
      "Entonar", 
      "Tañer", 
      "Modular", 
      "Vocalizar", 
      "Interpretar",
    ],
    respuestasCorrectas: ["Cantar"],
    fonetica: ["sɪŋ"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Sit",
    opciones: [
      "Sentar", 
      "Acomodar", 
      "Colocar", 
      "Asentar", 
      "Ubicar", 
      "Ponerse",
    ],
    respuestasCorrectas: ["Sentar"],
    fonetica: ["sɪt"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Sleep",
    opciones: [
      "Dormir", 
      "Reposar", 
      "Descansar", 
      "Echar un sueño", 
      "Soñar", 
      "Reposar",
    ],
    respuestasCorrectas: ["Dormir"],
    fonetica: ["slip"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Speak",
    opciones: [
      "Hablar", 
      "Conversar", 
      "Dialogar", 
      "Decir", 
      "Comunicarse", 
      "Charlar",
    ],
    respuestasCorrectas: ["Hablar"],
    fonetica: ["spik"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Spend",
    opciones: [
      "Gastar", 
      "Desembolsar", 
      "Invertir", 
      "Consumir", 
      "Despilfarrar", 
      "Derrochar",
    ],
    respuestasCorrectas: ["Gastar"],
    fonetica: ["spɛnd"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Split",
    opciones: [
      "Dividir/Partir", 
      "Separar", 
      "Cortar", 
      "Desunir", 
      "Fraccionar", 
      "Segmentar",
    ],
    respuestasCorrectas: ["Dividir/Partir"],
    fonetica: ["splɪt"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Stand",
    opciones: [
      "Poner de pie", 
      "Erguirse", 
      "Pararse", 
      "Levantarse", 
      "Posarse", 
      "Estar de pie",
    ],
    respuestasCorrectas: ["Poner de pie"],
    fonetica: ["stænd"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Steal",
    opciones: [
      "Robar", 
      "Saquear", 
      "Despojar", 
      "Hurtar", 
      "Desfalcar", 
      "Sustraer",
    ],
    respuestasCorrectas: ["Robar"],
    fonetica: ["stil"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Stick",
    opciones: [
      "Pegar", 
      "Adherir", 
      "Fijar", 
      "Enganchar", 
      "Unir", 
      "Colocar",
    ],
    respuestasCorrectas: ["Pegar"],
    fonetica: ["stɪk"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Sting",
    opciones: [
      "Picar (insecto)", 
      "Punzar", 
      "Aguijonear", 
      "Morder", 
      "Irritar", 
      "Lastimar",
    ],
    respuestasCorrectas: ["Picar (insecto)"],
    fonetica: ["stɪŋ"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Stink",
    opciones: [
      "Apestar", 
      "Heder", 
      "Oler mal", 
      "Emanar mal olor", 
      "Pestear", 
      "Desprender hedor",
    ],
    respuestasCorrectas: ["Apestar"],
    fonetica: ["stɪŋk"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Strike",
    opciones: [
      "Golpear", 
      "Atacar", 
      "Impactar", 
      "Dar un golpe", 
      "Arremeter", 
      "Chocar",
    ],
    respuestasCorrectas: ["Golpear"],
    fonetica: ["straɪk"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Swear",
    opciones: [
      "Jurar", 
      "Prometer", 
      "Asegurar", 
      "Proferir juramento", 
      "Declarar", 
      "Reafirmar",
    ],
    respuestasCorrectas: ["Jurar"],
    fonetica: ["swɛr"],
  },
];