export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 2,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 3,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 4,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 5,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 6,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 7,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 8,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 9,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 10,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 11,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 12,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 13,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 14,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 15,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 16,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 17,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 18,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 19,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
  {
    id: 20,
    texto: '',
    palabra: "",
    opciones: [
      "", 
      "", 
      "", 
      "", 
      "", 
      "",
    ],
    respuestasCorrectas: [""],
    fonetica: [""],
  },
];