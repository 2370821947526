import React from "react";
import { EstructuraCuestionario } from "data/setUpQuizzes/questionnaireStructure.js";
import { preguntasIniciales } from "../wordsRegular/l1.js";
import "verbs/lessons.css"

const tituloLeccion = "Lección 1: Verbo Regular";
const linkSiguiente = "/verb-regular/leccion-1-p2";



function Leccion1VR() {
  return (
    <EstructuraCuestionario
      tituloLeccion={tituloLeccion}
      linkSiguiente={linkSiguiente}
      preguntasIniciales={preguntasIniciales}
    />
  );
}

export default Leccion1VR;
