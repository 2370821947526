import React from "react";
import { Routes, Route } from "react-router-dom";

import Leccion1HumanBody from "vocabulary/humanBody/lessons/lesson1";
import Leccion2HumanBody from "vocabulary/humanBody/lessons/lesson2";

import Leccion1HumanBodyP2 from "vocabulary/humanBody/lessonsP2/lesson1P2";
import Leccion2HumanBodyP2 from "vocabulary/humanBody/lessonsP2/lesson2P2";

import Leccion1HumanBodyP3 from "vocabulary/humanBody/lessonsP3/lesson1P3";
import Leccion2HumanBodyP3 from "vocabulary/humanBody/lessonsP3/lesson2P3";

import NotFound from "home/notFound";

const HumanBodyRoutes = () => {
  return (
    <Routes>
        <Route path="leccion-1" element={<Leccion1HumanBody />} />
        <Route path="leccion-2" element={<Leccion2HumanBody />} />

        <Route path="leccion-1-p2" element={<Leccion1HumanBodyP2 />} />
        <Route path="leccion-2-p2" element={<Leccion2HumanBodyP2 />} />

        <Route path="leccion-1-p3" element={<Leccion1HumanBodyP3 />} />
        <Route path="leccion-2-p3" element={<Leccion2HumanBodyP3 />} />

        <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default HumanBodyRoutes;