import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';

import Home from 'home/home';
import Verbs from 'verbs/verbs';

import RegularHome from 'verbs/regular/regularHome';
import RegularRoutes from 'routes/verbsRoutes/regularRoutes';

import IrregularHome from 'verbs/irregular/irregularHome';
import IrregularRoutes from 'routes/verbsRoutes/irregularRoutes';


import Vocabulary from 'vocabulary/vocabulary';

import Colors from 'vocabulary/colors/colors';
import ColorsRoutes from 'routes/vocabularyRoutes/colorsRoutes';

import Professions from 'vocabulary/professions/professions';
import ProfessionsRoutes from 'routes/vocabularyRoutes/professionsRoutes';

import HumanBody from 'vocabulary/humanBody/humanBody';
import HumanBodyRoutes from 'routes/vocabularyRoutes/humanBodyRoutes';

import Animals from 'vocabulary/animals/animals';
import AnimalsRoutes from 'routes/vocabularyRoutes/animalsRoutes';

import Sports from 'vocabulary/sports/sports';
import SportsRoutes from 'routes/vocabularyRoutes/sportsRoutes';

import Technology from 'vocabulary/technology/technology';
import TechnologyRoutes from 'routes/vocabularyRoutes/technologyRoutes';




import NotFound from 'home/notFound';


function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/verbs" element={<Verbs />} />
              
              <Route path="/verbs/regular" element={<RegularHome />} />
              <Route path="verb-regular/*" element={<RegularRoutes />} />

              <Route path="/verbs/irregular" element={<IrregularHome />} />
              <Route path="/verb-irregular/*" element={<IrregularRoutes />} />


              <Route path="/vocabulary" element={<Vocabulary />} />

              <Route path="/vocabulary/colors" element={<Colors />} />
              <Route path="/vocabulary/colors/*" element={<ColorsRoutes />} />

              <Route path='/vocabulary/professions' element={<Professions />} />
              <Route path='/vocabulary/professions/*' element={<ProfessionsRoutes />} />

              <Route path='/vocabulary/humanBody' element={<HumanBody />} />
              <Route path='/vocabulary/humanBody/*' element={<HumanBodyRoutes />} />

              <Route path='/vocabulary/technology' element={<Technology />} />
              <Route path='/vocabulary/technology/*' element={<TechnologyRoutes />} />

              <Route path='/vocabulary/sports' element={<Sports />} />
              <Route path='/vocabulary/sports/*' element={<SportsRoutes />} />

              <Route path='/vocabulary/animals' element={<Animals />} />
              <Route path='/vocabulary/animals/*' element={<AnimalsRoutes />} />

              <Route path="*" element={<NotFound />} />
            </Routes>
        </Main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
