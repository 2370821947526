import React from "react";
import { EstructuraCuestionarioP3 } from "data/setUpQuizzesP3/questionnaireStructureP3.js";
import { preguntasIniciales } from "../wordsProfessions/l3";
import "verbs/lessons.css"

const tituloLeccion = "Lección 3 P3: Professions";
const linkSiguiente = "/vocabulary/professions/leccion-4";



function Leccion3ProfessionsP3() {
  return (
    <EstructuraCuestionarioP3
      tituloLeccion={tituloLeccion}
      linkSiguiente={linkSiguiente}
      preguntasIniciales={preguntasIniciales}
    />
  );
}

export default Leccion3ProfessionsP3;