import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../regular/regularHome.css";

const lecciones = [
  { id: 1, titulo: "LECCIÓN 1", preguntas: 20 },
  { id: 2, titulo: "LECCIÓN 2", preguntas: 20 },
  { id: 3, titulo: "LECCIÓN 3", preguntas: 20 },
  { id: 4, titulo: "LECCIÓN 4", preguntas: 20 },
  { id: 5, titulo: "LECCIÓN 5", preguntas: 20 },
  { id: 6, titulo: "LECCIÓN 6", preguntas: 19 },
];

const practicas = [
  {
    id: 1,
    titulo: "PRÁCTICA SESIÓN 1 A 5",
    descripcion: "100 preguntas, divididas en dos sesiones",
    detalle: "En total son dos prácticas, P1 y P2",
    ruta: "/primerapracticamenu",
  },
];

function IrregularHome() {
  useEffect(() => {
    const manejarPopState = (event) => {
      const message = "¿Estás seguro de que quieres salir?";
      const scrollPosition = window.scrollY;

      if (!window.confirm(message)) {
        window.history.pushState(null, null, window.location.pathname);
        setTimeout(() => {
          window.scrollTo(0, scrollPosition);
        }, 0);
      } else {
        window.removeEventListener("popstate", manejarPopState);
        window.history.back();
      }
    };

    const onPopState = (event) => {
      manejarPopState(event);
      event.preventDefault();
    };

    window.addEventListener("popstate", onPopState);
    window.history.pushState(null, null, window.location.pathname);

    return () => {
      window.removeEventListener("popstate", onPopState);
    };
  }, []);

  return (
    <div className="inicio">
      {/* Título agregado para identificar que estamos en el verbo regular */}
      <div className="contenedor-titulo">
        <p className="titulo-verbo-regular">Verbo Irregular</p>
      </div>
      {lecciones.map((leccion, index) => (
        <React.Fragment key={leccion.id}>
          <Link
            className="modulo lecciones"
            to={`/verb-irregular/leccion-${leccion.id}`}
          >
            <p className="titulo">{leccion.titulo}</p>
            <p className="contenido">
              Compuesto por {leccion.preguntas} preguntas
            </p>
          </Link>
          {/* Agregar una práctica después de cada bloque de 5 lecciones */}
          {(index + 1) % 5 === 0 && practicas[(index + 1) / 5 - 1] && (
            <Link
              className="modulo lecciones-practicas"
              to={practicas[(index + 1) / 5 - 1].ruta}
              onClick={() => window.scrollTo(0, 0)}
            >
              <p className="titulo">{practicas[(index + 1) / 5 - 1].titulo}</p>
              <p className="contenido">
                {practicas[(index + 1) / 5 - 1].descripcion}
              </p>
              <p className="contenido">
                {practicas[(index + 1) / 5 - 1].detalle}
              </p>
            </Link>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

export default IrregularHome;
