export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Meteorologist",
    opciones: [
      "Meteorólogo", 
      "Astrónomo", 
      "Climatólogo", 
      "Geólogo", 
      "Oceanógrafo", 
      "Ambientalista",
    ],
    respuestasCorrectas: ["Meteorólogo"],
    fonetica: ["ˌmitiəˈrɑləʤɪst"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Chemist",
    opciones: [
      "Químico", 
      "Farmacéutico", 
      "Biólogo", 
      "Físico", 
      "Microbiólogo", 
      "Bioquímico",
    ],
    respuestasCorrectas: ["Químico"],
    fonetica: ["ˈkɛmɪst"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Astronomer",
    opciones: [
      "Astrónomo", 
      "Astrofísico", 
      "Meteorólogo", 
      "Geólogo", 
      "Cartógrafo", 
      "Físico",
    ],
    respuestasCorrectas: ["Astrónomo"],
    fonetica: ["əˈstrɑnəmər"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Carpenter",
    opciones: [
      "Carpintero", 
      "Albañil", 
      "Ebanista", 
      "Constructor", 
      "Electricista", 
      "Mecánico",
    ],
    respuestasCorrectas: ["Carpintero"],
    fonetica: ["ˈkɑrpəntər"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Electrician",
    opciones: [
      "Electricista", 
      "Fontanero", 
      "Carpintero", 
      "Técnico en electrónica", 
      "Ingeniero eléctrico", 
      "Constructor",
    ],
    respuestasCorrectas: ["Electricista"],
    fonetica: ["ɪlɛkˈtrɪʃən"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Mechanic",
    opciones: [
      "Mecánico", 
      "Electricista", 
      "Fontanero", 
      "Carpintero", 
      "Soldador", 
      "Constructor",
    ],
    respuestasCorrectas: ["Mecánico"],
    fonetica: ["məˈkænɪk"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Construction worker",
    opciones: [
      "trabajador de construccion", 
      "Carpintero", 
      "Soldador", 
      "Plomero", 
      "Electricista", 
      "Mecánico",
    ],
    respuestasCorrectas: ["Trabajador de construccion"],
    fonetica: ["kənˈstrʌkʃən ˈwɜrkər"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Welder",
    opciones: [
      "Soldador", 
      "Carpintero", 
      "Albañil", 
      "Plomero", 
      "Electricista", 
      "Mecánico",
    ],
    respuestasCorrectas: ["Soldador"],
    fonetica: ["ˈwɛldər"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Plumber",
    opciones: [
      "Plomero", 
      "Fontanero", 
      "Electricista", 
      "Carpintero", 
      "Albañil", 
      "Mecánico",
    ],
    respuestasCorrectas: ["Plomero"],
    fonetica: ["ˈplʌmər"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Police Officer",
    opciones: [
      "Oficial de policía", 
      "Bombero", 
      "Guardia de seguridad", 
      "Detective", 
      "Investigador", 
      "Agente de tráfico",
    ],
    respuestasCorrectas: ["Oficial de policía"],
    fonetica: ["pəˈlis ˈɔfəsər"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Firefighter",
    opciones: [
      "Bombero", 
      "Oficial de policía", 
      "Guardia de seguridad", 
      "Paramédico", 
      "Investigador", 
      "Agente de tráfico",
    ],
    respuestasCorrectas: ["Bombero"],
    fonetica: ["ˈfaɪrˌfaɪtər"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Military",
    opciones: [
      "Militar", 
      "Soldado", 
      "Oficial", 
      "General", 
      "Teniente", 
      "Capitán",
    ],
    respuestasCorrectas: ["Militar"],
    fonetica: ["ˈmɪləˌtɛri"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Pilot",
    opciones: [
      "Piloto", 
      "Azafato", 
      "Ingeniero de vuelo", 
      "Controlador aéreo", 
      "Copiloto", 
      "Mecánico de aviación",
    ],
    respuestasCorrectas: ["Piloto"],
    fonetica: ["ˈpaɪlət"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Flight Attendant",
    opciones: [
      "Auxiliar de vuelo", 
      "Piloto", 
      "Ingeniero de vuelo", 
      "Controlador aéreo", 
      "Mecánico de aviación", 
      "Oficial de abordo",
    ],
    respuestasCorrectas: ["Auxiliar de vuelo"],
    fonetica: ["flaɪt əˈtɛndənt"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Barista",
    opciones: [
      "Barista", 
      "Mesero", 
      "Camarero", 
      "Chef", 
      "Cocinero", 
      "Repostero",
    ],
    respuestasCorrectas: ["Barista"],
    fonetica: ["Barista"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Receptionist",
    opciones: [
      "Recepcionista", 
      "Secretario", 
      "Asistente administrativo", 
      "Conserje", 
      "Telefonista", 
      "Auxiliar de oficina",
    ],
    respuestasCorrectas: ["Recepcionista"],
    fonetica: ["rɪˈsɛpʃənɪst"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Accountant",
    opciones: [
      "contable", 
      "contador", 
      "financiero", 
      "auditor", 
      "consultor", 
      "administrador",
    ],
    respuestasCorrectas: ["contable"],
    fonetica: ["əˈkaʊntənt"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Actress",
    opciones: [
      "actriz", 
      "actora", 
      "interprete", 
      "dramaturga", 
      "performer", 
      "comediante",
    ],
    respuestasCorrectas: ["actriz"],
    fonetica: ["ˈæktrəs"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Politician",
    opciones: [
      "político", 
      "diplomático", 
      "legislador", 
      "senador", 
      "diputado", 
      "gobernante",
    ],
    respuestasCorrectas: ["político"],
    fonetica: ["ˌpɑləˈtɪʃən"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Air hostess",
    opciones: [
      "azafata", 
      "aeromoza", 
      "auxiliar de vuelo", 
      "tripulante de cabina", 
      "asistente de vuelo", 
      "comisaria de abordo",
    ],
    respuestasCorrectas: ["azafata"],
    fonetica: ["ɛr ˈhoʊstəs"],
  },
];