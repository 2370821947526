import React from "react";
import "./Footer.css"

function Footer() {
  return (
    <footer>
      <div className="marquee-container">
        <p className="marquee-text">"¡El éxito comienza con la determinación, no te rindas!" 💪</p>
      </div>
    </footer>
  );
}

export default Footer;
