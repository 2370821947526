import React from "react";
import { EstructuraCuestionario } from "data/setUpQuizzes/questionnaireStructure";
import { preguntasIniciales } from "../wordsProfessions/l5";
import "verbs/lessons.css"

const tituloLeccion = "Lección 5: Professions";
const linkSiguiente = "/vocabulary/professions/leccion-5-p2";



function Leccion5Professions() {
  return (
    <EstructuraCuestionario
      tituloLeccion={tituloLeccion}
      linkSiguiente={linkSiguiente}
      preguntasIniciales={preguntasIniciales}
    />
  );
}

export default Leccion5Professions;

