export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Cost",
    opciones: [
      "Costar", 
      "Valer", 
      "Tener un precio", 
      "Salir en", 
      "Ascender a", 
      "Implicar un gasto de"
    ],
    respuestasCorrectas: ["Costar"],
    fonetica: ["kɑst"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Creep",
    opciones: [
      "Arrastrar", 
      "Deslizarse", 
      "Gatear", 
      "Moverse lentamente", 
      "Reptar", 
      "Escabullirse",
    ],
    respuestasCorrectas: ["Arrastrar"],
    fonetica: ["krip"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Cut",
    opciones: [
      "Cortar", 
      "Seccionar", 
      "Tajar", 
      "Amputar", 
      "Partir", 
      "Dividir",
    ],
    respuestasCorrectas: ["Cortar"],
    fonetica: ["kʌt"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Dig",
    opciones: [
      "Excavar", 
      "Cavar", 
      "Hundir", 
      "Abrir un hueco", 
      "Remover tierra", 
      "Hacer un hoyo",
    ],
    respuestasCorrectas: ["Excavar"],
    fonetica: ["dɪɡ"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Do",
    opciones: [
      "Hacer", 
      "Realizar", 
      "Ejecutar", 
      "Llevar a cabo", 
      "Desempeñar", 
      "Completar",
    ],
    respuestasCorrectas: ["Hacer"],
    fonetica: ["du"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Draw",
    opciones: [
      "Dibujar", 
      "Trazar", 
      "Esbozar", 
      "Ilustrar", 
      "Bosquejar", 
      "Delimitar",
    ],
    respuestasCorrectas: ["Dibujar"],
    fonetica: ["drɔ"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Drink",
    opciones: [
      "Beber", 
      "Tomar", 
      "Ingerir", 
      "Sorber", 
      "Devorar", 
      "Absorber",
    ],
    respuestasCorrectas: ["Beber"],
    fonetica: ["drɪŋk"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Drive",
    opciones: [
      "Conducir", 
      "Manejar", 
      "Dirigir", 
      "Llevar", 
      "Pilotar", 
      "Operar",
    ],
    respuestasCorrectas: ["Conducir"],
    fonetica: ["draɪv"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Eat",
    opciones: [
      "Comer", 
      "Devorar", 
      "Ingerir", 
      "Masticar", 
      "Alimentarse", 
      "Tragar",
    ],
    respuestasCorrectas: ["Comer"],
    fonetica: ["it"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Fall",
    opciones: [
      "Caer", 
      "Descender", 
      "Derrumbarse", 
      "Desplomarse", 
      "Bajar", 
      "Desmoronarse",
    ],
    respuestasCorrectas: ["Caer"],
    fonetica: ["fɔl"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Feed",
    opciones: [
      "Alimentar", 
      "Nutrir", 
      "Dar de comer", 
      "Proveer alimento", 
      "Cebar", 
      "Sustentar",
    ],
    respuestasCorrectas: ["Alimentar"],
    fonetica: ["fid"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Feel",
    opciones: [
      "Sentir", 
      "Percibir", 
      "Experimentar", 
      "Notar", 
      "Palpar", 
      "Padecer",
    ],
    respuestasCorrectas: ["Sentir"],
    fonetica: ["fil"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Fight",
    opciones: [
      "Pelear", 
      "Combatir", 
      "Luchar", 
      "Enfrentarse", 
      "Batallar", 
      "Disputar",
    ],
    respuestasCorrectas: ["Pelear"],
    fonetica: ["faɪt"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Find",
    opciones: [
      "Encontrar", 
      "Descubrir", 
      "Hallar", 
      "Localizar", 
      "Identificar", 
      "Detectar",
    ],
    respuestasCorrectas: ["Encontrar"],
    fonetica: ["faɪnd"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Flee",
    opciones: [
      "Huir", 
      "Escapar", 
      "Evadirse", 
      "Fugarse", 
      "Alejarse", 
      "Salir corriendo",
    ],
    respuestasCorrectas: ["Huir"],
    fonetica: ["fli"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Fly",
    opciones: [
      "Volar", 
      "Planear", 
      "Ascender", 
      "Despegar", 
      "Surcar el aire", 
      "Navegar",
    ],
    respuestasCorrectas: ["Volar"],
    fonetica: ["flaɪ"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Forbid",
    opciones: [
      "Prohibir", 
      "Impedir", 
      "Vedarse", 
      "Interdictar", 
      "Vetar", 
      "Privar",
    ],
    respuestasCorrectas: ["Prohibir"],
    fonetica: ["fərˈbɪd"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Forget",
    opciones: [
      "Olvidar", 
      "Omitir", 
      "Ignorar", 
      "Desatender", 
      "Dejar de lado", 
      "No recordar",
    ],
    respuestasCorrectas: ["Olvidar"],
    fonetica: ["fərˈɡɛt"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Forgive",
    opciones: [
      "Perdonar", 
      "Absolver", 
      "Exonerar", 
      "Disculpar", 
      "Indultar", 
      "Pasar por alto",
    ],
    respuestasCorrectas: ["Perdonar"],
    fonetica: ["fərˈɡɪv"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Freeze",
    opciones: [
      "Helar", 
      "Congelar", 
      "Solidificar", 
      "Refrigerar", 
      "Petrificar", 
      "Glaciar",
    ],
    respuestasCorrectas: ["Helar"],
    fonetica: ["friz"],
  },
];