import React from "react";
import { EstructuraCuestionarioP2 } from "data/setUpQuizzesP2/questionnaireStructureP2.js";
import { preguntasIniciales } from "../wordsProfessions/l3";
import "verbs/lessons.css"

const tituloLeccion = "Lección 3 P2: Professions";
const linkSiguiente = "/vocabulary/professions/leccion-3-p3";



function Leccion3ProfessionsP2() {
  return (
    <EstructuraCuestionarioP2
      tituloLeccion={tituloLeccion}
      linkSiguiente={linkSiguiente}
      preguntasIniciales={preguntasIniciales}
    />
  );
}

export default Leccion3ProfessionsP2;
