import { useState } from "react";

export function useResultados(
  preguntas,
  setPreguntas,
  barajarArray,
  setRespuestasUsuario
) {
  const [resultados, setResultados] = useState(null);
  const [puntajeTotal, setPuntajeTotal] = useState(0);

  // Función para normalizar texto y eliminar acentos
  const normalizarTexto = (texto) =>
    texto
      .normalize("NFD") // Descompone caracteres acentuados
      .replace(/[\u0300-\u036f]/g, "") // Elimina acentos
      .toLowerCase() // Convierte a minúsculas
      .trim();

  // Función para manejar el envío de las respuestas
  const manejarEnvio = (respuestasUsuario) => {
    const preguntaSinResponder = preguntas.find(
      (pregunta) => !respuestasUsuario[`pregunta${pregunta.id}`]
    );

    if (preguntaSinResponder) {
      // Desplazar a la primera pregunta sin respuesta
      setTimeout(() => {
        const preguntaElement = document.querySelector(
          `#pregunta-${preguntaSinResponder.id}`
        );
        if (preguntaElement) {
          preguntaElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 100);
      return; // Detener el proceso de envío si hay preguntas sin responder
    }

    // Función para calificar las respuestas
    const calificarRespuestas = (respuestaUsuario, respuestasCorrectas) => {
      const respuestasUsuario = respuestaUsuario
        .split(/[/,]/) // Separar respuestas por '/' o ','
        .map((respuesta) => normalizarTexto(respuesta)) // Normalizar cada respuesta
        .sort();

      const respuestasCorrectasNormalizadas = respuestasCorrectas[0]
        .split(/[/,]/) // Separar respuestas por '/' o ','
        .map((respuesta) => normalizarTexto(respuesta)) // Normalizar respuestas correctas
        .sort();

      // Verificar si las respuestas coinciden
      return (
        respuestasUsuario.length === respuestasCorrectasNormalizadas.length &&
        respuestasUsuario.every(
          (respuesta, index) =>
            respuesta === respuestasCorrectasNormalizadas[index]
        )
      );
    };

    const resultadosCalificados = preguntas.map((pregunta) => ({
      ...pregunta,
      correcto: calificarRespuestas(
        respuestasUsuario[`pregunta${pregunta.id}`],
        pregunta.respuestasCorrectas
      ),
    }));

    const puntaje = resultadosCalificados.reduce(
      (total, pregunta) => total + (pregunta.correcto ? 1 : 0),
      0
    );

    setResultados(resultadosCalificados);
    setPuntajeTotal(puntaje);

    // Desplazar a la parte final de la página para ver los botones de resultados
    setTimeout(() => {
      const resultadosElement = document.querySelector(".resultados");
      if (resultadosElement) {
        resultadosElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 100);
  };

  // Función para manejar la repetición del cuestionario
  const manejarRepetirCuestionario = () => {
    if (window.confirm("¿Seguro que quieres repetir el cuestionario?")) {
      setResultados(null);
      setPuntajeTotal(0);
      setRespuestasUsuario({}); // Limpiar las respuestas del usuario
      setPreguntas((prevPreguntas) =>
        barajarArray(
          prevPreguntas.map((pregunta) => ({
            ...pregunta,
          }))
        )
      );

      // Desplazar al inicio del cuestionario
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return { resultados, puntajeTotal, manejarEnvio, manejarRepetirCuestionario };
}
