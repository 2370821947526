export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Open",
    opciones: [
      "Abrir", 
      "Desplegar", 
      "Desbloquear", 
      "Destapar", 
      "Descubrir", 
      "Iniciar",
    ],
    respuestasCorrectas: ["Abrir"],
    fonetica: ["ˈoʊpən"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Operate",
    opciones: [
      "Operar", 
      "Manejar", 
      "Controlar", 
      "Ejecutar", 
      "Manipular", 
      "Gestionar",
    ],
    respuestasCorrectas: ["Operar"],
    fonetica: ["ˈɑpəˌreɪt"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Order",
    opciones: [
      "Ordenar/Pedir", 
      "Solicitar", 
      "Mandar", 
      "Encargar", 
      "Requerir", 
      "Exigir",
    ],
    respuestasCorrectas: ["Ordenar/Pedir"],
    fonetica: ["ˈɔrdər"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Pass",
    opciones: [
      "Pasar", 
      "Cruzar", 
      "Atravesar", 
      "Transferir", 
      "Desplazarse", 
      "Superar",
    ],
    respuestasCorrectas: ["Pasar"],
    fonetica: ["pæs"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Pat",
    opciones: [
      "Acariciar", 
      "Palpar", 
      "Golpear suavemente", 
      "Mimar", 
      "Tocar", 
      "Dar una palmadita",
    ],
    respuestasCorrectas: ["Acariciar"],
    fonetica: ["pæt"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Permit",
    opciones: [
      "Permitir", 
      "Autorizar", 
      "Consentir", 
      "Aceptar", 
      "Facilitar", 
      "Tolerar",
    ],
    respuestasCorrectas: ["Permitir"],
    fonetica: ["ˈpɜrˌmɪt"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Place",
    opciones: [
      "Colocar", 
      "Poner", 
      "Situar", 
      "Depositar", 
      "Ubicar", 
      "Posicionar",
    ],
    respuestasCorrectas: ["Colocar"],
    fonetica: ["pleɪs"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Plan",
    opciones: [
      "Planear", 
      "Organizar", 
      "Programar", 
      "Preparar", 
      "Idear", 
      "Proyectar",
    ],
    respuestasCorrectas: ["Planear"],
    fonetica: ["plæn"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Play",
    opciones: [
      "Jugar", 
      "Participar", 
      "Tocar (un instrumento)", 
      "Representar", 
      "Interpretar", 
      "Actuar",
    ],
    respuestasCorrectas: ["Jugar"],
    fonetica: ["pleɪ"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Plug",
    opciones: [
      "Enchufar", 
      "Conectar", 
      "Insertar", 
      "Introducir", 
      "Colocar", 
      "Encajar",
    ],
    respuestasCorrectas: ["Enchufar"],
    fonetica: ["plʌɡ"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Point",
    opciones: [
      "Apuntar", 
      "Señalar", 
      "Indicar", 
      "Marcar", 
      "Mostrar", 
      "Dirigir",
    ],
    respuestasCorrectas: ["Apuntar"],
    fonetica: ["pɔɪnt"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Practice",
    opciones: [
      "Practicar", 
      "Ejercitar", 
      "Ensayar", 
      "Repetir", 
      "Entrenar", 
      "Poner en práctica",
    ],
    respuestasCorrectas: ["Practicar"],
    fonetica: ["ˈpræktəs"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Pray",
    opciones: [
      "Rezar", 
      "Orar", 
      "Suplicar", 
      "Implorar", 
      "Pedir", 
      "Rogar",
    ],
    respuestasCorrectas: ["Rezar"],
    fonetica: ["preɪ"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Prefer",
    opciones: [
      "Preferir", 
      "Escoger", 
      "Elegir", 
      "Seleccionar", 
      "Optar por", 
      "Anteponer",
    ],
    respuestasCorrectas: ["Preferir"],
    fonetica: ["prəˈfɜr"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Prepare",
    opciones: [
      "Preparar", 
      "Alistar", 
      "Organizar", 
      "Disponer", 
      "Acondicionar", 
      "Arreglar",
    ],
    respuestasCorrectas: ["Preparar"],
    fonetica: ["priˈpɛr"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Prevent",
    opciones: [
      "Prevenir", 
      "Evitar", 
      "Impedir", 
      "Anticipar", 
      "Detener", 
      "Obstruir",
    ],
    respuestasCorrectas: ["Prevenir"],
    fonetica: ["prɪˈvɛnt"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Proceed",
    opciones: [
      "Proceder", 
      "Continuar", 
      "Avanzar", 
      "Proseguir", 
      "Seguir", 
      "Actuar",
    ],
    respuestasCorrectas: ["Proceder"],
    fonetica: ["prəˈsid"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Produce",
    opciones: [
      "Producir", 
      "Crear", 
      "Generar", 
      "Fabricar", 
      "Elaborar", 
      "Hacer",
    ],
    respuestasCorrectas: ["Producir"],
    fonetica: ["ˈproʊdus"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Program",
    opciones: [
      "Programar", 
      "Planificar", 
      "Organizar", 
      "Codificar", 
      "Preparar", 
      "Agendar",
    ],
    respuestasCorrectas: ["Programar"],
    fonetica: ["ˈproʊˌɡræm"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Pronounce",
    opciones: [
      "Pronunciar", 
      "Decir", 
      "Articular", 
      "Enunciar", 
      "Emitir", 
      "Expresar",
    ],
    respuestasCorrectas: ["Pronunciar"],
    fonetica: ["prəˈnaʊns"],
  },
];