import { useState } from "react";

export function useResultados(preguntas, setPreguntas, barajarArray, setRespuestasUsuario) {
  const [resultados, setResultados] = useState(null);
  const [puntajeTotal, setPuntajeTotal] = useState(0);

  // Función para manejar el envío de las respuestas
  const manejarEnvio = (respuestasUsuario) => {
    const preguntaSinResponder = preguntas.find(
      (pregunta) => !respuestasUsuario[`pregunta${pregunta.id}`]
    );

    if (preguntaSinResponder) {
      // Desplazar a la primera pregunta sin respuesta
      setTimeout(() => {
        const preguntaElement = document.querySelector(`#pregunta-${preguntaSinResponder.id}`);
        if (preguntaElement) {
          // Aseguramos que el desplazamiento sea visible
          preguntaElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, 100);
      return; // Detener el proceso de envío si hay preguntas sin responder
    }

    const calificar = (respuestas, correctas) => {
      if (Array.isArray(correctas)) {
        return (
          JSON.stringify(respuestas.sort()) === JSON.stringify(correctas.sort())
        );
      }
      return respuestas[0] === correctas[0];
    };

    const resultadosCalificados = preguntas.map((pregunta) => ({
      ...pregunta,
      correcto: calificar(
        respuestasUsuario[`pregunta${pregunta.id}`],
        pregunta.respuestasCorrectas
      ),
    }));

    const puntaje = resultadosCalificados.reduce(
      (total, pregunta) => total + (pregunta.correcto ? 1 : 0),
      0
    );

    setResultados(resultadosCalificados);
    setPuntajeTotal(puntaje);

    // Desplazar a la parte final de la página para ver los botones de resultados
    setTimeout(() => {
      const resultadosElement = document.querySelector(".resultados");
      if (resultadosElement) {
        resultadosElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }, 100);
  };

  // Función para manejar la repetición del cuestionario
  const manejarRepetirCuestionario = () => {
    if (window.confirm("¿Seguro que quieres repetir el cuestionario?")) {
      setResultados(null);
      setPuntajeTotal(0);
      setRespuestasUsuario({}); // Limpiar las respuestas del usuario
      setPreguntas((prevPreguntas) =>
        barajarArray(
          prevPreguntas.map((pregunta) => ({
            ...pregunta,
            opciones: barajarArray(pregunta.opciones),
          }))
        )
      );

      // Desplazar al inicio del cuestionario
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  };

  return { resultados, puntajeTotal, manejarEnvio, manejarRepetirCuestionario };
}
