export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Sweep",
    opciones: [
      "Barrer", 
      "Limpiar", 
      "Escobar", 
      "Recoger", 
      "Despejar", 
      "Asear",
    ],
    respuestasCorrectas: ["Barrer"],
    fonetica: ["swip"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Swim",
    opciones: [
      "Nadar", 
      "Zambullirse", 
      "Bucear", 
      "Sumergirse", 
      "Flotar", 
      "Navegar",
    ],
    respuestasCorrectas: ["Nadar"],
    fonetica: ["swɪm"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Swing",
    opciones: [
      "Columpiar/Balancear", 
      "Oscilar", 
      "Mover", 
      "Bambolear", 
      "Mezclar", 
      "Agitar",
    ],
    respuestasCorrectas: ["Columpiar/Balancear"],
    fonetica: ["swɪŋ"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Take",
    opciones: [
      "Tomar/Llevarse", 
      "Coger", 
      "Agarrar", 
      "Capturar", 
      "Obtener", 
      "Recibir",
    ],
    respuestasCorrectas: ["Tomar/Llevarse"],
    fonetica: ["teɪk"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Teach",
    opciones: [
      "Enseñar", 
      "Educar", 
      "Instruir", 
      "Formar", 
      "Mostrar", 
      "Capacitar",
    ],
    respuestasCorrectas: ["Enseñar"],
    fonetica: ["tiʧ"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Tear",
    opciones: [
      "Romper/Rasgar", 
      "Desgarrar", 
      "Despedazar", 
      "Destrozar", 
      "Deshacer", 
      "Hendir",
    ],
    respuestasCorrectas: ["Romper/Rasgar"],
    fonetica: ["tɛr"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Tell",
    opciones: [
      "Decir", 
      "Contar", 
      "Relatar", 
      "Narrar", 
      "Informar", 
      "Comunicar",
    ],
    respuestasCorrectas: ["Decir"],
    fonetica: ["tɛl"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Think",
    opciones: [
      "Pensar", 
      "Reflexionar", 
      "Razonar", 
      "Meditar", 
      "Considerar", 
      "Creer"
    ],
    respuestasCorrectas: ["Pensar"],
    fonetica: ["θɪŋk"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Throw",
    opciones: [
      "Lanzar", 
      "Arrojar", 
      "Echar", 
      "Tirar", 
      "Aventar", 
      "Propulsar"
    ],
    respuestasCorrectas: ["Lanzar"],
    fonetica: ["θroʊ"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Undergo",
    opciones: [
      "Sufrir", 
      "Padecer", 
      "Experimentar", 
      "Soportar", 
      "Pasar por", 
      "Afrontar",
    ],
    respuestasCorrectas: ["Sufrir"],
    fonetica: ["ˌʌndərˈɡoʊ"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Understand",
    opciones: [
      "Comprender/Entender", 
      "Percibir", 
      "Captar", 
      "Darse cuenta", 
      "Reconocer", 
      "Apreciar",
    ],
    respuestasCorrectas: ["Comprender/Entender"],
    fonetica: ["ˌʌndərˈstænd"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Wake",
    opciones: [
      "Despertar", 
      "Levantarse", 
      "Desvelar", 
      "Reavivar", 
      "Espabilar", 
      "Despertarse",
    ],
    respuestasCorrectas: ["Despertar"],
    fonetica: ["weɪk"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Wear",
    opciones: [
      "Calzar/Vestir", 
      "Llevar", 
      "Usar", 
      "Portar", 
      "Poner", 
      "Enfundarse",
    ],
    respuestasCorrectas: ["Calzar/Vestir"],
    fonetica: ["wɛr"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Win",
    opciones: [
      "Ganar", 
      "Triunfar", 
      "Conquistar", 
      "Lograr", 
      "Alcanzar", 
      "Obtener",
    ],
    respuestasCorrectas: ["Ganar"],
    fonetica: ["wɪn"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Wind",
    opciones: [
      "Dar cuenta", 
      "Enrollar", 
      "Enrollarse", 
      "Extender", 
      "Girar", 
      "Enrollarse",
    ],
    respuestasCorrectas: ["Dar cuenta"],
    fonetica: ["wɪnd"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Write",
    opciones: [
      "Escribir", 
      "Redactar", 
      "Anotar", 
      "Transcribir", 
      "Componer", 
      "Registrar",
    ],
    respuestasCorrectas: ["Escribir"],
    fonetica: ["raɪt"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Bear",
    opciones: [
      "Soportar", 
      "Aguantar", 
      "Tolerar", 
      "Sufrir", 
      "Resistir", 
      "Padecer",
    ],
    respuestasCorrectas: ["Soportar"],
    fonetica: ["bɛr"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Bet",
    opciones: [
      "Apostar", 
      "Jugar", 
      "Arriesgar", 
      "Ganar", 
      "Pujar", 
      "Retar",
    ],
    respuestasCorrectas: ["Apostar"],
    fonetica: ["bɛt"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Bib",
    opciones: [
      "Pujar", 
      "Ofrecer", 
      "Subastar", 
      "Competir", 
      "Presentar oferta", 
      "Ejecutar puja",
    ],
    respuestasCorrectas: ["Pujar"],
    fonetica: ["bɪb"],
  },
];