import React from "react";
import { Link } from "react-router-dom";
import "./Header.css"
import Button from "@mui/material/Button";
import HomeTwoToneIcon from "@mui/icons-material/HomeTwoTone";

const manejarClickInicio = (event) => {
  if (!window.confirm("¿Seguro que quieres regresar al inicio?")) {
    event.preventDefault(); // Previene la acción de redirección si el usuario cancela
  } else {
    window.scrollTo(0, 0);
  }
};

function Header() {
  return (
    <header>
      <Button
        endIcon={<HomeTwoToneIcon />}
        component={Link}
        to={"/"}
        onClick={manejarClickInicio}
        size="large"
        disableElevation
        disableRipple
        sx={{
          padding: "8px 30px",
          backgroundColor: "transparent",
          boxShadow: "none",
          cursor: "pointer",
          fontSize: "20px",
        }}
        color="primary"
      >
        HOME
      </Button>
    </header>
  );
}

export default Header;
