import { useState } from "react";

export function useRespuestas() {
  const [respuestasUsuario, setRespuestasUsuario] = useState({});

  const manejarCambioTexto = (preguntaId, texto) => {
    setRespuestasUsuario((prevState) => ({
      ...prevState,
      [`pregunta${preguntaId}`]: texto, // Guardar la respuesta del usuario sin recortar espacios
    }));
  };

  return {
    respuestasUsuario,
    manejarCambioTexto,
    setRespuestasUsuario, // Este setter se devuelve para ser utilizado por el componente
  };
}
