export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Make",
    opciones: [
      "Hacer", 
      "Crear", 
      "Producir", 
      "Fabricar", 
      "Confeccionar", 
      "Realizar",
    ],
    respuestasCorrectas: ["Hacer"],
    fonetica: ["meɪk"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Mean",
    opciones: [
      "Significar", 
      "Implicar", 
      "Connotar", 
      "Denotar", 
      "Expresar", 
      "Referirse a",
    ],
    respuestasCorrectas: ["Significar"],
    fonetica: ["min"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Meet",
    opciones: [
      "Conocer", 
      "Encontrarse", 
      "Reunirse", 
      "Verse", 
      "Coincidir", 
      "Entrevistarse",
    ],
    respuestasCorrectas: ["Conocer"],
    fonetica: ["mit"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Note",
    opciones: [
      "Notar", 
      "Percibir", 
      "Observar", 
      "Darse cuenta", 
      "Advertir", 
      "Detectar",
    ],
    respuestasCorrectas: ["Notar"],
    fonetica: ["noʊt"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Overcome",
    opciones: [
      "Vencer", 
      "Superar", 
      "Conquistar", 
      "Dominar", 
      "Triunfar sobre", 
      "Sobreponerse",
    ],
    respuestasCorrectas: ["Vencer"],
    fonetica: ["ˈoʊvərˌkʌm"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Oversleep",
    opciones: [
      "Dormir demasiado", 
      "Quedarse dormido", 
      "Reposar en exceso", 
      "Despertarse tarde", 
      "Pasarse de sueño", 
      "No despertar a tiempo",
    ],
    respuestasCorrectas: ["Dormir demasiado"],
    fonetica: ["ˌoʊvərˈslip"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Overtake",
    opciones: [
      "Rebasar", 
      "Adelantar", 
      "Sobrepasar", 
      "Superar", 
      "Dejar atrás", 
      "Pasar",
    ],
    respuestasCorrectas: ["Rebasar"],
    fonetica: ["ˈoʊvərˌteɪk"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Pay",
    opciones: [
      "Pagar", 
      "Abonar", 
      "Desembolsar", 
      "Saldar", 
      "Liquidar", 
      "Remunerar",
    ],
    respuestasCorrectas: ["Pagar"],
    fonetica: ["peɪ"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Put",
    opciones: [
      "Poner", 
      "Colocar", 
      "Situar", 
      "Ubicar", 
      "Disponer", 
      "Instalar",
    ],
    respuestasCorrectas: ["Poner"],
    fonetica: ["pʊt"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Read",
    opciones: [
      "Leer", 
      "Examinar", 
      "Interpretar", 
      "Ojear", 
      "Consultar", 
      "Revisar",
    ],
    respuestasCorrectas: ["Leer"],
    fonetica: ["rid"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Replay",
    opciones: [
      "Responder", 
      "Contestar", 
      "Replicar", 
      "Reaccionar", 
      "Devolver", 
      "Responder nuevamente",
    ],
    respuestasCorrectas: ["Responder"],
    fonetica: ["riˈpleɪ"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Ride",
    opciones: [
      "Montar", 
      "Cabalgar", 
      "Conducir", 
      "Viajar", 
      "Pasear", 
      "Subir a",
    ],
    respuestasCorrectas: ["Montar"],
    fonetica: ["raɪd"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Ring",
    opciones: [
      "Sonar", 
      "Repicar", 
      "Resonar", 
      "Vibrar", 
      "Tañer", 
      "Tintinear",
    ],
    respuestasCorrectas: ["Sonar"],
    fonetica: ["rɪŋ"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Rise",
    opciones: [
      "Subir", 
      "Ascender", 
      "Elevarse", 
      "Aumentar", 
      "Levantar", 
      "Emerger",
    ],
    respuestasCorrectas: ["Subir"],
    fonetica: ["raɪz"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Run",
    opciones: [
      "Correr", 
      "Trojar", 
      "Galopar", 
      "Desplazarse", 
      "Moverse rápidamente", 
      "Andar ligero",
    ],
    respuestasCorrectas: ["Correr"],
    fonetica: ["rʌn"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Say",
    opciones: [
      "Decir", 
      "Expresar", 
      "Manifestar", 
      "Afirmar", 
      "Proclamar", 
      "Declarar",
    ],
    respuestasCorrectas: ["Decir"],
    fonetica: ["seɪ"],
  },
  {
    id: 17,
    texto: '',
    palabra: "See",
    opciones: [
      "Ver", 
      "Observar", 
      "Mirar", 
      "Contemplar", 
      "Divisar", 
      "Percibir",
    ],
    respuestasCorrectas: ["Ver"],
    fonetica: ["si"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Seek",
    opciones: [
      "Buscar", 
      "Explorar", 
      "Indagar", 
      "Procurar", 
      "Intentar", 
      "Esforzarse por",
    ],
    respuestasCorrectas: ["Buscar"],
    fonetica: ["sik"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Sell",
    opciones: [
      "Vender", 
      "Comercializar", 
      "Despachar", 
      "Expender", 
      "Subastar", 
      "Ofrecer",
    ],
    respuestasCorrectas: ["Vender"],
    fonetica: ["sɛl"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Send",
    opciones: [
      "Enviar", 
      "Mandar", 
      "Remitir", 
      "Despachar", 
      "Transportar", 
      "Expedir",
    ],
    respuestasCorrectas: ["Enviar"],
    fonetica: ["sɛnd"],
  },
];