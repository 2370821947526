export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Wish",
    opciones: [
      "Desear", 
      "Anhelar", 
      "Aspirar", 
      "Querer", 
      "Esperar", 
      "Ambicionar",
    ],
    respuestasCorrectas: ["Desear"],
    fonetica: ["wɪʃ"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Work",
    opciones: [
      "Trabajar", 
      "Laborar", 
      "Ejecutar", 
      "Realizar", 
      "Funcionar", 
      "Ocuparse",
    ],
    respuestasCorrectas: ["Trabajar"],
    fonetica: ["wɜrk"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Worry",
    opciones: [
      "Preocupar", 
      "Inquietar", 
      "Angustiar", 
      "Afligir", 
      "Ansiedad", 
      "Tensionar",
    ],
    respuestasCorrectas: ["Preocupar"],
    fonetica: ["ˈwɜri"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Wound",
    opciones: [
      "Herir/Lastimar", 
      "Lesionar", 
      "Dañar", 
      "Perjudicar", 
      "Golpear", 
      "Magullar",
    ],
    respuestasCorrectas: ["Herir/Lastimar"],
    fonetica: ["wund"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Wrap",
    opciones: [
      "Envolver", 
      "Empacar", 
      "Cubrir", 
      "Forrar", 
      "Arropar", 
      "Encerrar",
    ],
    respuestasCorrectas: ["Envolver"],
    fonetica: ["ræp"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Include",
    opciones: [
      "Incluir", 
      "Integrar", 
      "Incorporar", 
      "Agregar", 
      "Comprender", 
      "Contener",
    ],
    respuestasCorrectas: ["Incluir"],
    fonetica: ["ɪnˈklud"],
  },
];