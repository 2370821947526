export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Mustard",
    opciones: [
      "Mostaza", 
      "Oro", 
      "Ocre", 
      "Ámbar", 
      "Amarillo", 
      "Bronce",
    ],
    respuestasCorrectas: ["Mostaza"],
    fonetica: ["ˈmʌstərd"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Salmon",
    opciones: [
      "Salmón", 
      "Coral", 
      "Durazno", 
      "Rosa", 
      "Rosado", 
      "Albaricoque",
    ],
    respuestasCorrectas: ["Salmón"],
    fonetica: ["ˈsæmən"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Coffee",
    opciones: [
      "Café", 
      "Marrón", 
      "Moca", 
      "Chocolate", 
      "Expreso", 
      "Castaño",
    ],
    respuestasCorrectas: ["Café"],
    fonetica: ["ˈkɑfi"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Purple",
    opciones: [
      "Púrpura", 
      "Violeta", 
      "Lavanda", 
      "Malva", 
      "Lila", 
      "Ciruela",
    ],
    respuestasCorrectas: ["Púrpura"],
    fonetica: ["ˈpɜrpəl"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Light Pink",
    opciones: [
      "Rosa claro", 
      "Rubor", 
      "Rosa bebé", 
      "Durazno", 
      "Rosa", 
      "Rosa pálido",
    ],
    respuestasCorrectas: ["Rosa claro"],
    fonetica: ["laɪt pɪŋk"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Mint Green",
    opciones: [
      "Verde menta", 
      "Verde espuma de mar", 
      "Verde jade", 
      "Pistacho", 
      "Lima", 
      "Verde primavera",
    ],
    respuestasCorrectas: ["Verde menta"],
    fonetica: ["mɪnt ɡrin"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Light Blue",
    opciones: [
      "Azul claro", 
      "Azul cielo", 
      "Azul bebé", 
      "Azul polvo", 
      "Azul hielo", 
      "Azul pervinca",
    ],
    respuestasCorrectas: ["Azul claro"],
    fonetica: ["laɪt blu"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Emerald Green",
    opciones: [
      "Verde esmeralda", 
      "Verde bosque", 
      "Verde musgo", 
      "Verde mar", 
      "Verde oliva", 
      "Verde Kelly",
    ],
    respuestasCorrectas: ["Verde esmeralda"],
    fonetica: ["ˈɛmrəld ɡrin"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Ochre",
    opciones: [
      "Ocre", 
      "Oro", 
      "Mostaza", 
      "Ámbar", 
      "Bronce", 
      "Leonado",
    ],
    respuestasCorrectas: ["Ocre"],
    fonetica: ["ˈoʊkər"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Terracotta",
    opciones: [
      "Terracota", 
      "Óxido", 
      "Ladrillo", 
      "Cobre", 
      "Arcilla", 
      "Coral",
    ],
    respuestasCorrectas: ["Terracota"],
    fonetica: ["Terracotta"],
  },  
  {
    id: 11,
    texto: '',
    palabra: "Pearl",
    opciones: [
      "Perla", 
      "Marfil", 
      "Crema", 
      "Alabastro", 
      "Nieve", 
      "Hueso",
    ],
    respuestasCorrectas: ["Perla"],
    fonetica: ["pɜrl"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Navy Blue",
    opciones: [
      "Azul marino", 
      "Azul medianoche", 
      "Índigo", 
      "Cobalto", 
      "Azul real", 
      "Azul marino intenso",
    ],
    respuestasCorrectas: ["Azul marino"],
    fonetica: ["ˈneɪvi blu"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Olive Green",
    opciones: [
      "Verde oliva", 
      "Verde militar", 
      "Verde musgo", 
      "Verde cazador", 
      "Verde salvia", 
      "Verde bosque",
    ],
    respuestasCorrectas: ["Verde oliva"],
    fonetica: ["ˈɑləv ɡrin"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Lemon Yellow",
    opciones: [
      "Amarillo limón", 
      "Amarillo canario", 
      "Oro", 
      "Narciso", 
      "Azafrán", 
      "Botón de oro",
    ],
    respuestasCorrectas: ["Amarillo limón"],
    fonetica: ["ˈlɛmən ˈjɛloʊ"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Lavender",
    opciones: [
      "Lavanda", 
      "Lila", 
      "Azul pervinca", 
      "Malva", 
      "Orquídea", 
      "Cardo",
    ],
    respuestasCorrectas: ["Lavanda"],
    fonetica: ["ˈlævəndər"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Dark Gray",
    opciones: [
      "Gris oscuro", 
      "Gris pizarra", 
      "Carbón", 
      "Grafito", 
      "Metal de arma", 
      "Estaño",
    ],
    respuestasCorrectas: ["Gris oscuro"],
    fonetica: ["dɑrk ɡreɪ"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Petroleum Blue",
    opciones: [
      "Azul petróleo", 
      "Azul verdoso", 
      "Azul mar profundo", 
      "Egeo", 
      "Azul pavo real", 
      "Cian",
    ],
    respuestasCorrectas: ["Azul petróleo"],
    fonetica: ["pəˈtroʊliəm blu"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Wine Red",
    opciones: [
      "Rojo vino", 
      "Borgoña", 
      "Granate", 
      "Carmesí", 
      "Baya", 
      "Granate oscuro",
    ],
    respuestasCorrectas: ["Rojo vino"],
    fonetica: ["waɪn rɛd"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Honey",
    opciones: [
      "Miel", 
      "Ámbar", 
      "Dorado", 
      "Caramelo", 
      "Toffee", 
      "Oro",
    ],
    respuestasCorrectas: ["Miel"],
    fonetica: ["ˈhʌni"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Turquoise Blue",
    opciones: [
      "Azul turquesa", 
      "Aqua", 
      "Teal", 
      "Cian", 
      "Azul Tiffany", 
      "Azul huevo de petirrojo",
    ],
    respuestasCorrectas: ["Azul turquesa"],
    fonetica: ["ˈtɜrkwɔɪz blu"],
  },  
];