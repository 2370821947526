export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Laugh",
    opciones: [
      "Reír", 
      "Carcajear", 
      "Soltar una risotada", 
      "Mofa", 
      "Burla", 
      "Jovialidad",
    ],
    respuestasCorrectas: ["Reír"],
    fonetica: ["læf"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Lay",
    opciones: [
      "Poner", 
      "Colocar", 
      "Depositar", 
      "Situar", 
      "Disponer", 
      "Ubicar",
    ],
    respuestasCorrectas: ["Poner"],
    fonetica: ["leɪ"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Learn",
    opciones: [
      "Aprender", 
      "Estudiar", 
      "Adquirir conocimiento", 
      "Asimilar", 
      "Instruirse", 
      "Formarse",
    ],
    respuestasCorrectas: ["Aprender"],
    fonetica: ["lɜrn"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Lie",
    opciones: [
      "Mentir", 
      "Engañar", 
      "Falsificar", 
      "Ocultar la verdad", 
      "Falsear", 
      "Inventar",
    ],
    respuestasCorrectas: ["Mentir"],
    fonetica: ["laɪ"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Light",
    opciones: [
      "Prender/Encender", 
      "Iluminar", 
      "Alumbrar", 
      "Brillar", 
      "Luz", 
      "Luminar",
    ],
    respuestasCorrectas: ["Prender/Encender"],
    fonetica: ["laɪt"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Like",
    opciones: [
      "Gustar", 
      "Agradar", 
      "Encantar", 
      "Disfrutar", 
      "Apreciar", 
      "Preferir",
    ],
    respuestasCorrectas: ["Gustar"],
    fonetica: ["laɪk"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Listen",
    opciones: [
      "Escuchar (Acción)", 
      "Oír", 
      "Prestar atención", 
      "Atender", 
      "Sintonizar", 
      "Percibir",
    ],
    respuestasCorrectas: ["Escuchar (Acción)"],
    fonetica: ["ˈlɪsən"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Live",
    opciones: [
      "Vivir", 
      "Existir", 
      "Habitar", 
      "Residir", 
      "Subsistir", 
      "Sobrevivir",
    ],
    respuestasCorrectas: ["Vivir"],
    fonetica: ["lɪv"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Look",
    opciones: [
      "Ver", 
      "Mirar", 
      "Observar", 
      "Contemplar", 
      "Examinar", 
      "Divisar",
    ],
    respuestasCorrectas: ["Ver"],
    fonetica: ["lʊk"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Love",
    opciones: [
      "Amar", 
      "Querer", 
      "Adorar", 
      "Apreciar", 
      "Encantar", 
      "Idolatrar",
    ],
    respuestasCorrectas: ["Amar"],
    fonetica: ["lʌv"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Manage",
    opciones: [
      "Manejar", 
      "Administrar", 
      "Controlar", 
      "Dirigir", 
      "Gestionar", 
      "Supervisar",
    ],
    respuestasCorrectas: ["Manejar"],
    fonetica: ["ˈmænəʤ"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Mark",
    opciones: [
      "Marcar", 
      "Señalar", 
      "Indicar", 
      "Tachar", 
      "Anotar", 
      "Destacar",
    ],
    respuestasCorrectas: ["Marcar"],
    fonetica: ["mɑrk"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Marry",
    opciones: [
      "Casarse", 
      "Unirse en matrimonio", 
      "Contraer matrimonio", 
      "Desposarse", 
      "Enlazarse", 
      "Nupciarse",
    ],
    respuestasCorrectas: ["Casarse"],
    fonetica: ["ˈmɛri"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Measure",
    opciones: [
      "Medir", 
      "Cuantificar", 
      "Evaluar", 
      "Calibrar", 
      "Dimensionar", 
      "Valorar",
    ],
    respuestasCorrectas: ["Medir"],
    fonetica: ["ˈmɛʒər"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Miss",
    opciones: [
      "Extrañar", 
      "Echar de menos", 
      "Perder", 
      "Desaprovechar", 
      "Fallar", 
      "Omitir",
    ],
    respuestasCorrectas: ["Extrañar"],
    fonetica: ["mɪs"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Move",
    opciones: [
      "Mover", 
      "Trasladar", 
      "Desplazar", 
      "Transportar", 
      "Agitar", 
      "Cambiar de lugar",
    ],
    respuestasCorrectas: ["Mover"],
    fonetica: ["muv"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Need",
    opciones: [
      "Necesitar", 
      "Requerir", 
      "Precisar", 
      "Demandar", 
      "Carecer de", 
      "Exigir",
    ],
    respuestasCorrectas: ["Necesitar"],
    fonetica: ["nid"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Observe",
    opciones: [
      "Observar", 
      "Vigilar", 
      "Mirar", 
      "Examinar", 
      "Notar", 
      "Percibir",
    ],
    respuestasCorrectas: ["Observar"],
    fonetica: ["əbˈzɜrv"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Obtain",
    opciones: [
      "Obtener", 
      "Adquirir", 
      "Conseguir", 
      "Recibir", 
      "Lograr", 
      "Alcanzar",
    ],
    respuestasCorrectas: ["Obtener"],
    fonetica: ["əbˈteɪn"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Offer",
    opciones: [
      "Ofrecer", 
      "Proponer", 
      "Presentar", 
      "Suministrar", 
      "Brindar", 
      "Proveer",
    ],
    respuestasCorrectas: ["Ofrecer"],
    fonetica: ["ˈɔfər"],
  },
];