export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Eyelid",
    opciones: [
      "Párpado", 
      "Ojo", 
      "Ceja", 
      "Pestaña", 
      "Nariz", 
      "Barbilla",
    ],
    respuestasCorrectas: ["Párpado"],
    fonetica: ["ˈaɪˌlɪd"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Eyelash",
    opciones: [
      "Pestaña", 
      "Ojo", 
      "Ceja", 
      "Párpado", 
      "Labio", 
      "Nariz",
    ],
    respuestasCorrectas: ["Pestaña"],
    fonetica: ["ˈaɪˌlæʃ"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Nostril",
    opciones: [
      "Orificio de la nariz", 
      "Nariz", 
      "Boca", 
      "Ojo", 
      "Labio", 
      "Párpado",
    ],
    respuestasCorrectas: ["Orificio de la nariz"],
    fonetica: ["ˈnɑstrɪl"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Chin",
    opciones: [
      "Barbilla", 
      "Mandíbula", 
      "Labio", 
      "Nariz", 
      "Ojo", 
      "Ceja",
    ],
    respuestasCorrectas: ["Barbilla"],
    fonetica: ["ʧɪn"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Lip",
    opciones: [
      "Labio", 
      "Boca", 
      "Barbilla", 
      "Nariz", 
      "Párpado", 
      "Ceja",
    ],
    respuestasCorrectas: ["Labio"],
    fonetica: ["lɪp"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Tongue",
    opciones: [
      "Lengua", 
      "Labio", 
      "Diente", 
      "Mandíbula", 
      "Garganta", 
      "Paladar",
    ],
    respuestasCorrectas: ["Lengua"],
    fonetica: ["tʌŋ"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Tooth",
    opciones: [
      "Diente", 
      "Muela", 
      "Lengua", 
      "Mandíbula", 
      "Labio", 
      "Paladar",
    ],
    respuestasCorrectas: ["Diente"],
    fonetica: ["tuθ"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Teeth",
    opciones: [
      "Dientes", 
      "Muela", 
      "Lengua", 
      "Mandíbula", 
      "Labio", 
      "Paladar",
    ],
    respuestasCorrectas: ["Dientes"],
    fonetica: ["tiθ"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Forearm",
    opciones: [
      "Antebrazo", 
      "Brazo", 
      "Codo", 
      "Muñeca", 
      "Mano", 
      "Hombro",
    ],
    respuestasCorrectas: ["Antebrazo"],
    fonetica: ["ˈfɔˌrɑrm"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Wrist",
    opciones: [
      "Muñeca", 
      "Antebrazo", 
      "Codo", 
      "Mano", 
      "Dedo", 
      "Brazo",
    ],
    respuestasCorrectas: ["Muñeca"],
    fonetica: ["rɪst"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Palm",
    opciones: [
      "Palma", 
      "Mano", 
      "Dedos", 
      "Muñeca", 
      "Brazo", 
      "Codo",
    ],
    respuestasCorrectas: ["Palma"],
    fonetica: ["pɑm"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Thumb",
    opciones: [
      "Pulgar", 
      "Dedo", 
      "Muñeca", 
      "Palma", 
      "Mano", 
      "Brazo",
    ],
    respuestasCorrectas: ["Pulgar"],
    fonetica: ["θʌm"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Heel",
    opciones: [
      "Talón", 
      "Pie", 
      "Tobillo", 
      "Planta", 
      "Dedo del pie", 
      "Pantorrilla",
    ],
    respuestasCorrectas: ["Talón"],
    fonetica: ["hil"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Ankle",
    opciones: [
      "Tobillo", 
      "Pie", 
      "Talón", 
      "Pantorrilla", 
      "Rodilla", 
      "Pierna",
    ],
    respuestasCorrectas: ["Tobillo"],
    fonetica: ["ˈæŋkəl"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Lung",
    opciones: [
      "Pulmón", 
      "Corazón", 
      "Hígado", 
      "Riñón", 
      "Estómago", 
      "Intestino",
    ],
    respuestasCorrectas: ["Pulmón"],
    fonetica: ["lʌŋ"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Stomach",
    opciones: [
      "Estómago", 
      "Intestinos", 
      "Riñón", 
      "Hígado", 
      "Corazón", 
      "Pulmón",
    ],
    respuestasCorrectas: ["Estómago"],
    fonetica: ["ˈstʌmək"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Throat",
    opciones: [
      "Garganta", 
      "Cuello", 
      "Laringe", 
      "Faringe", 
      "Tráquea", 
      "Esófago",
    ],
    respuestasCorrectas: ["Garganta"],
    fonetica: ["θroʊt"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Vein",
    opciones: [
      "Vena", 
      "Arteria", 
      "Capilar", 
      "Válvula", 
      "Ventrículo", 
      "Aurícula",
    ],
    respuestasCorrectas: ["Vena"],
    fonetica: ["veɪn"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Intestines",
    opciones: [
      "Intestinos", 
      "Estómago", 
      "Riñón", 
      "Hígado", 
      "Pulmón", 
      "Corazón",
    ],
    respuestasCorrectas: ["Intestinos"],
    fonetica: ["ɪnˈtɛstənz"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Kidney",
    opciones: [
      "Riñón", 
      "Hígado", 
      "Intestinos", 
      "Estómago", 
      "Corazón", 
      "Pulmón",
    ],
    respuestasCorrectas: ["Riñón"],
    fonetica: ["ˈkɪdni"],
  },
  {
    id: 21,
    texto: '',
    palabra: "Bone",
    opciones: [
      "Hueso", 
      "Músculo", 
      "Tendón", 
      "Ligamento", 
      "Cartílago", 
      "Articulación",
    ],
    respuestasCorrectas: ["Hueso"],
    fonetica: ["boʊn"],
  },
  {
    id: 22,
    texto: '',
    palabra: "Liver",
    opciones: [
      "Hígado", 
      "Riñón", 
      "Estómago", 
      "Intestinos", 
      "Corazón", 
      "Pulmón",
    ],
    respuestasCorrectas: ["Hígado"],
    fonetica: ["ˈlɪvər"],
  },
];