export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Head",
    opciones: [
      "Cabeza", 
      "Mano", 
      "Pie", 
      "Brazo", 
      "Pierna", 
      "Espalda",
    ],
    respuestasCorrectas: ["Cabeza"],
    fonetica: ["hɛd"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Face",
    opciones: [
      "Cara", 
      "Ojo", 
      "Nariz", 
      "Boca", 
      "Oreja", 
      "Cuello",
    ],
    respuestasCorrectas: ["Cara"],
    fonetica: ["feɪs"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Eye",
    opciones: [
      "Ojo", 
      "Boca", 
      "Oreja", 
      "Nariz", 
      "Cara", 
      "Cuello",
    ],
    respuestasCorrectas: ["Ojo"],
    fonetica: ["aɪ"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Nose",
    opciones: [
      "Nariz", 
      "Ojo", 
      "Boca", 
      "Oreja", 
      "Cara", 
      "Cuello",
    ],
    respuestasCorrectas: ["Nariz"],
    fonetica: ["noʊz"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Mouth",
    opciones: [
      "Boca", 
      "Ojo", 
      "Nariz", 
      "Oreja", 
      "Cara", 
      "Cuello",
    ],
    respuestasCorrectas: ["Boca"],
    fonetica: ["maʊθ"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Neck",
    opciones: [
      "Cuello", 
      "Cabeza", 
      "Hombro", 
      "Brazo", 
      "Espalda", 
      "Cara",
    ],
    respuestasCorrectas: ["Cuello"],
    fonetica: ["nɛk"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Ear",
    opciones: [
      "Oreja", 
      "Ojo", 
      "Nariz", 
      "Boca", 
      "Cuello", 
      "Cara",
    ],
    respuestasCorrectas: ["Oreja"],
    fonetica: ["ir"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Shoulder",
    opciones: [
      "Hombro", 
      "Brazo", 
      "Mano", 
      "Pierna", 
      "Cuello", 
      "Espalda",
    ],
    respuestasCorrectas: ["Hombro"],
    fonetica: ["ˈʃoʊldər"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Back",
    opciones: [
      "Espalda", 
      "Nariz", 
      "Cuello", 
      "Cabeza", 
      "Hombro", 
      "Brazo",
    ],
    respuestasCorrectas: ["Espalda"],
    fonetica: ["bæk"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Arm",
    opciones: [
      "Brazo", 
      "Pierna", 
      "Mano", 
      "Hombro", 
      "Cabeza", 
      "Espalda",
    ],
    respuestasCorrectas: ["Brazo"],
    fonetica: ["ɑrm"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Chest",
    opciones: [
      "Pecho", 
      "Espalda", 
      "Brazo", 
      "Pierna", 
      "Mano", 
      "Cuello",
    ],
    respuestasCorrectas: ["Pecho"],
    fonetica: ["ʧɛst"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Hand",
    opciones: [
      "Mano", 
      "Dedo de la mano", 
      "Brazo", 
      "Pierna", 
      "Cabeza", 
      "Ojo",
    ],
    respuestasCorrectas: ["Mano"],
    fonetica: ["hænd"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Finger",
    opciones: [
      "Dedo de la mano", 
      "Dedo del pie", 
      "Brazo", 
      "Pierna", 
      "Mano", 
      "Codo",
    ],
    respuestasCorrectas: ["Dedo de la mano"],
    fonetica: ["ˈfɪŋɡər"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Elbow",
    opciones: [
      "Codo", 
      "Rodilla", 
      "Hombro", 
      "Mano", 
      "Pie", 
      "Brazo",
    ],
    respuestasCorrectas: ["Codo"],
    fonetica: ["ˈɛlˌboʊ"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Leg",
    opciones: [
      "Pierna", 
      "Pie", 
      "Brazo", 
      "Mano", 
      "Rodilla", 
      "Codo",
    ],
    respuestasCorrectas: ["Pierna"],
    fonetica: ["lɛɡ"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Foot",
    opciones: [
      "Pie", 
      "Mano", 
      "Cabeza", 
      "Brazo", 
      "Ojo", 
      "Cara",
    ],
    respuestasCorrectas: ["Pie"],
    fonetica: ["fʊt"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Toe",
    opciones: [
      "Dedo del pie", 
      "Dedo de la mano", 
      "Pie", 
      "Mano", 
      "Ojo", 
      "Nariz",
    ],
    respuestasCorrectas: ["Dedo del pie"],
    fonetica: ["toʊ"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Knee",
    opciones: [
      "Rodilla", 
      "Codo", 
      "Pierna", 
      "Brazo", 
      "Pie", 
      "Cabeza",
    ],
    respuestasCorrectas: ["Rodilla"],
    fonetica: ["ni"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Heart",
    opciones: [
      "Corazón", 
      "Cerebro", 
      "Estómago", 
      "Hígado", 
      "Pulmón", 
      "Riñón",
    ],
    respuestasCorrectas: ["Corazón"],
    fonetica: ["hɑrt"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Brain",
    opciones: [
      "Cerebro", 
      "Corazón", 
      "Ojo", 
      "Oído", 
      "Estómago", 
      "Pulmón",
    ],
    respuestasCorrectas: ["Cerebro"],
    fonetica: ["breɪn"],
  },
  {
    id: 21,
    texto: '',
    palabra: "Eyebrow",
    opciones: [
      "Ceja", 
      "Pestaña", 
      "Ojo", 
      "Oreja", 
      "Nariz", 
      "Boca",
    ],
    respuestasCorrectas: ["Ceja"],
    fonetica: ["ˈaɪˌbraʊ"],
  },
];