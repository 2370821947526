import { useState } from "react";

export function useRespuestas() {
  const [respuestasUsuario, setRespuestasUsuario] = useState({});

  const manejarCambioRadio = (preguntaId, opcion) => {
    setRespuestasUsuario((prevState) => ({
      ...prevState,
      [`pregunta${preguntaId}`]: [opcion], // Solo se guarda una opción seleccionada
    }));
  };

  

  return {
    respuestasUsuario,
    manejarCambioRadio,
    setRespuestasUsuario, // Este setter se devuelve para ser utilizado por el componente
  };
}
