export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Secretary",
    opciones: [
      "Secretario", 
      "Asistente", 
      "Oficinista", 
      "Recepcionista", 
      "Administrador", 
      "Auxiliar administrativo",
    ],
    respuestasCorrectas: ["Secretario"],
    fonetica: ["ˈsɛkrəˌtɛri"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Social worker",
    opciones: [
      "Trabajador social", 
      "Consejero", 
      "Asistente social", 
      "Terapeuta", 
      "Educador", 
      "Orientador",
    ],
    respuestasCorrectas: ["Trabajador social"],
    fonetica: ["ˈsoʊʃəl ˈwɜrkər"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Surgeon",
    opciones: [
      "Cirujano", 
      "Médico", 
      "Especialista", 
      "Doctor", 
      "Ortopedista", 
      "Neurocirujano",
    ],
    respuestasCorrectas: ["Cirujano"],
    fonetica: ["ˈsɜrʤən"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Travel agent",
    opciones: [
      "Agente de viajes", 
      "Consultor de viajes", 
      "Operador turístico", 
      "Asesor de viajes", 
      "Planificador de viajes", 
      "Coordinador de viajes",
    ],
    respuestasCorrectas: ["Agente de viajes"],
    fonetica: ["ˈtrævəl ˈeɪʤənt"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Waiter",
    opciones: [
      "Camarero", 
      "Mesero", 
      "Atendente", 
      "Servidor", 
      "Garzón", 
      "Auxiliar de servicio",
    ],
    respuestasCorrectas: ["Camarero"],
    fonetica: ["ˈweɪtər"],
  },
  {
    id: 6,
    texto: '',
    palabra: "waitress",
    opciones: [
      "Camarera", 
      "Mesera", 
      "Servidor", 
      "Asistente de servicio", 
      "Garzona", 
      "Barista",
    ],
    respuestasCorrectas: ["Camarera"],
    fonetica: ["ˈweɪtrəs"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Cobbler",
    opciones: [
      "Zapatero", 
      "Reparador de calzado", 
      "Arreglador de zapatos", 
      "Zapatista", 
      "Zapatero remendón", 
      "Calzadero",
    ],
    respuestasCorrectas: ["Zapatero"],
    fonetica: ["ˈkɑblər"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Counselor",
    opciones: [
      "Consejero", 
      "Asesor", 
      "Orientador", 
      "Consultor", 
      "Guía", 
      "Mentor",
    ],
    respuestasCorrectas: ["Consejero"],
    fonetica: ["ˈkaʊnsələr"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Dancer",
    opciones: [
      "Bailarín", 
      "Coreógrafo", 
      "Instructor de danza", 
      "Artista de baile", 
      "Ejecutante", 
      "Bailador",
    ],
    respuestasCorrectas: ["Bailarín"],
    fonetica: ["ˈdænsər"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Factory worker",
    opciones: [
      "Trabajador de fábrica", 
      "Obrero", 
      "Operario", 
      "Empleado industrial", 
      "Técnico de planta", 
      "Fabricante",
    ],
    respuestasCorrectas: ["Trabajador de fábrica"],
    fonetica: ["ˈfæktəri ˈwɜrkər"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Farmer",
    opciones: [
      "Granjero", 
      "Agricultor", 
      "Campesino", 
      "Horticultor", 
      "Productor", 
      "Fincalero",
    ],
    respuestasCorrectas: ["Granjero"],
    fonetica: ["ˈfɑrmər"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Fisherman",
    opciones: [
      "Pescador", 
      "Marinero", 
      "Pescador artesanal", 
      "Barquero", 
      "Navegante", 
      "Aquacultor",
    ],
    respuestasCorrectas: ["Pescador"],
    fonetica: ["ˈfɪʃərˌmæn"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Gardener",
    opciones: [
      "Jardinero", 
      "Paisajista", 
      "Horticultor", 
      "Florista", 
      "Botánico", 
      "Cultivador",
    ],
    respuestasCorrectas: ["Jardinero"],
    fonetica: ["ˈɡɑrdənər"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Goldsmith",
    opciones: [
      "Orfebre", 
      "Joyero", 
      "Metalúrgico", 
      "Platero", 
      "Fundidor", 
      "Cincelador",
    ],
    respuestasCorrectas: ["Orfebre"],
    fonetica: ["ˈɡoʊldˌsmɪθ"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Hairdresser",
    opciones: [
      "Peluquero", 
      "Estilista", 
      "Barbero", 
      "Colorista", 
      "Cortador de cabello", 
      "Especialista en belleza",
    ],
    respuestasCorrectas: ["Peluquero"],
    fonetica: ["ˈhɛrˌdrɛsər"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Housewife",
    opciones: [
      "Ama de casa", 
      "Dueña de casa", 
      "Hogarera", 
      "Encargada del hogar", 
      "Esposa", 
      "Cuidadora del hogar",
    ],
    respuestasCorrectas: ["Ama de casa"],
    fonetica: ["ˈhaʊˌswaɪf"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Judge",
    opciones: [
      "Juez", 
      "Magistrado", 
      "Arbitro", 
      "Tribuno", 
      "Justiciero", 
      "Jurista",
    ],
    respuestasCorrectas: ["Juez"],
    fonetica: ["ʤʌʤ"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Lecturer",
    opciones: [
      "Profesor universitario", 
      "Docente", 
      "Catedrático", 
      "Instructor", 
      "Educador", 
      "Académico",
    ],
    respuestasCorrectas: ["Profesor universitario"],
    fonetica: ["ˈlɛkʧərər"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Miner",
    opciones: [
      "Minero", 
      "Excavador", 
      "Trabajador de mina", 
      "Picador", 
      "Extraccionista", 
      "Operador minero",
    ],
    respuestasCorrectas: ["Minero"],
    fonetica: ["ˈmaɪnər"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Monk",
    opciones: [
      "Monje", 
      "Religioso", 
      "Fray", 
      "Hermitaño", 
      "Monacato", 
      "Abad",
    ],
    respuestasCorrectas: ["Monje"],
    fonetica: ["mʌŋk"],
  },
];