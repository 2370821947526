import React from "react";
import { Routes, Route } from "react-router-dom";

import Leccion1Professions from 'vocabulary/professions/lessons/lesson1';
import Leccion2Professions from 'vocabulary/professions/lessons/lesson2';
import Leccion3Professions from 'vocabulary/professions/lessons/lesson3';
import Leccion4Professions from "vocabulary/professions/lessons/lesson4";
import Leccion5Professions from "vocabulary/professions/lessons/lesson5";
import Leccion6Professions from "vocabulary/professions/lessons/lesson6";

import Leccion1ProfessionsP2 from 'vocabulary/professions/lessonsP2/lesson1P2';
import Leccion2ProfessionsP2 from 'vocabulary/professions/lessonsP2/lesson2P2';
import Leccion3ProfessionsP2 from 'vocabulary/professions/lessonsP2/lesson3P2';
import Leccion4ProfessionsP2 from "vocabulary/professions/lessonsP2/lesson4P2";
import Leccion5ProfessionsP2 from "vocabulary/professions/lessonsP2/lesson5P2";
import Leccion6ProfessionsP2 from "vocabulary/professions/lessonsP2/lesson6P2";

import Leccion1ProfessionsP3 from 'vocabulary/professions/lessonsP3/lesson1P3';
import Leccion2ProfessionsP3 from 'vocabulary/professions/lessonsP3/lesson2P3';
import Leccion3ProfessionsP3 from 'vocabulary/professions/lessonsP3/lesson3P3';
import Leccion4ProfessionsP3 from "vocabulary/professions/lessonsP3/lesson4P3";
import Leccion5ProfessionsP3 from "vocabulary/professions/lessonsP3/lesson5P3";
import Leccion6ProfessionsP3 from "vocabulary/professions/lessonsP3/lesson6P3";

import NotFound from "home/notFound";

const ProfessionsRoutes = () => {
  return (
    <Routes>
      <Route path="leccion-1" element={<Leccion1Professions />} />
      <Route path="leccion-2" element={<Leccion2Professions />} />
      <Route path="leccion-3" element={<Leccion3Professions />} />
      <Route path="leccion-4" element={<Leccion4Professions />} />
      <Route path="leccion-5" element={<Leccion5Professions />} />
      <Route path="leccion-6" element={<Leccion6Professions />} />

      <Route path="leccion-1-p2" element={<Leccion1ProfessionsP2 />} />
      <Route path="leccion-2-p2" element={<Leccion2ProfessionsP2 />} />
      <Route path="leccion-3-p2" element={<Leccion3ProfessionsP2 />} />
      <Route path="leccion-4-p2" element={<Leccion4ProfessionsP2 />} />
      <Route path="leccion-5-p2" element={<Leccion5ProfessionsP2 />} />
      <Route path="leccion-6-p2" element={<Leccion6ProfessionsP2 />} />

      <Route path="leccion-1-p3" element={<Leccion1ProfessionsP3 />} />
      <Route path="leccion-2-p3" element={<Leccion2ProfessionsP3 />} />
      <Route path="leccion-3-p3" element={<Leccion3ProfessionsP3 />} />
      <Route path="leccion-4-p3" element={<Leccion4ProfessionsP3 />} />
      <Route path="leccion-5-p3" element={<Leccion5ProfessionsP3 />} />
      <Route path="leccion-6-p3" element={<Leccion6ProfessionsP3 />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default ProfessionsRoutes;
