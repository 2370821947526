import React from "react";
import { Link } from "react-router-dom";
import { usePreguntas } from "./useQuestions";
import { useRespuestas } from "./useAnswers";
import { useResultados } from "./useResults";
import { speakWord } from "data/responsiveVoiceHelper";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import RepeatIcon from "@mui/icons-material/Repeat";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export function EstructuraCuestionario({
  tituloLeccion,
  linkSiguiente,
  preguntasIniciales,
}) {
  const { preguntas, setPreguntas, barajarArray } =
    usePreguntas(preguntasIniciales);
  const { respuestasUsuario, manejarCambioRadio, setRespuestasUsuario } =
    useRespuestas();
  const {
    resultados,
    puntajeTotal,
    manejarEnvio,
    manejarRepetirCuestionario,
    cuestionarioKey,
  } = useResultados(
    preguntas,
    setPreguntas,
    barajarArray,
    setRespuestasUsuario
  );

  return (
    <div className="lecciones_INGLES">
      <div className="introduccion">
        <p>{tituloLeccion}</p>
      </div>
      <div key={cuestionarioKey}>
        {preguntas.map((pregunta, index) => (
          <div
            id={`pregunta-${pregunta.id}`}
            key={pregunta.id}
            className="pregunta"
          >
            <div className="pregunta-header">
              <span>{index + 1}. Pregunta</span>
              <span>1 punto</span>
            </div>
            <div className="pregunta-texto">
              <p>
                Select what is the translation of the word below into Spanish?
              </p>
            </div>
            <div className="pregunta-palabra-leccion">
              <div>
              {pregunta.palabra}
              <p className="parrafo-fonetica">{pregunta.fonetica}</p>
              </div>
              <button
                onClick={() => speakWord(pregunta.palabra)}
                type="button"
                className="button-api"
              >
                🔊 Play
              </button>
            </div>
            <div className="opciones">
              {pregunta.opciones.map((opcion, i) => (
                <label key={i}>
                  <input
                    type="radio"
                    name={`pregunta${pregunta.id}`}
                    onChange={() => manejarCambioRadio(pregunta.id, opcion)}
                    checked={
                      respuestasUsuario[`pregunta${pregunta.id}`]?.includes(
                        opcion
                      ) || false
                    }
                  />
                  <span
                    style={{
                      backgroundColor:
                        resultados &&
                        pregunta.respuestasCorrectas.includes(opcion)
                          ? "lightgreen"
                          : "transparent",
                      fontSize: "18px",
                    }}
                  >
                    {opcion}
                  </span>
                </label>
              ))}
            </div>
            {resultados && (
              <div
                className={`resultado ${
                  resultados[index]?.correcto ? "correcto" : "incorrecto"
                }`}
              >
                {resultados[index]?.correcto ? "Correcto" : "Incorrecto"}
              </div>
            )}
          </div>
        ))}
        <div className="finalizar-cuestionario">
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            onClick={() => manejarEnvio(respuestasUsuario)}
            color="info"
          >
            Send
          </Button>
        </div>
        {resultados && (
          <div className="resultados">
            <div className="puntaje-total">
              Puntaje total: {puntajeTotal} / {preguntas.length} (
              {((puntajeTotal / preguntas.length) * 100).toFixed(2)}%)
            </div>

            <div className="acciones">
              <Button
                variant="contained"
                endIcon={<RepeatIcon />}
                onClick={manejarRepetirCuestionario}
                color="inherit"
              >
                REPEAT
              </Button>
            </div>
            {puntajeTotal === preguntas.length && (
              <div className="mensaje-exito">
                <p>Al parecer tienes suerte, ¡Sígueme!.</p>
                <br />
                <Button
                  endIcon={<NavigateNextIcon />}
                  component={Link}
                  to={linkSiguiente}
                  variant="contained"
                  color="success"
                >
                  SIGUIENTE
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
