export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Balance",
    opciones: [
      "Equilibrar", 
      "Armonía", 
      "Estabilidad", 
      "Sintonía", 
      "Compensación", 
      "Igualdad",
    ],
    respuestasCorrectas: ["Equilibrar"],
    fonetica: ["ˈbæləns"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Bark",
    opciones: [
      "Ladrar", 
      "Corteza", 
      "Aullido", 
      "Gruñido", 
      "Rugido", 
      "Sonido",
    ],
    respuestasCorrectas: ["Ladrar"],
    fonetica: ["bɑrk"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Beg",
    opciones: [
      "Suplicar/Mendigar", 
      "Implorar", 
      "Rogar", 
      "Pedir", 
      "Solicitar", 
      "Exigir",
    ],
    respuestasCorrectas: ["Suplicar/Mendigar"],
    fonetica: ["bɛɡ"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Behave",
    opciones: [
      "Comportarse", 
      "Portarse", 
      "Actuar", 
      "Conducirse", 
      "Manejarse", 
      "Proceder",
    ],
    respuestasCorrectas: ["Comportarse"],
    fonetica: ["bɪˈheɪv"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Believe",
    opciones: [
      "Creer", 
      "Confiar", 
      "Aceptar", 
      "Admitir", 
      "Asumir", 
      "Imaginar",
    ],
    respuestasCorrectas: ["Creer"],
    fonetica: ["bɪˈliv"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Belong",
    opciones: [
      "Pertenecer", 
      "Integrarse", 
      "Ser parte", 
      "Incluirse", 
      "Encajar", 
      "Unirse",
    ],
    respuestasCorrectas: ["Pertenecer"],
    fonetica: ["bɪˈlɔŋ"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Bless",
    opciones: [
      "Bendecir", 
      "Santificar", 
      "Consagrar", 
      "Alabar", 
      "Venerar", 
      "Glorificar",
    ],
    respuestasCorrectas: ["Bendecir"],
    fonetica: ["blɛs"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Boil",
    opciones: [
      "Hervir", 
      "Calentar", 
      "Cocer", 
      "Guisar", 
      "Cocinar", 
      "Burbujear",
    ],
    respuestasCorrectas: ["Hervir"],
    fonetica: ["bɔɪl"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Borrow",
    opciones: [
      "Pedir prestado", 
      "Tomar prestado", 
      "Solicitar", 
      "Adquirir", 
      "Recibir", 
      "Obtener",
    ],
    respuestasCorrectas: ["Pedir prestado"],
    fonetica: ["ˈbɑˌroʊ"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Bother",
    opciones: [
      "Molestar", 
      "Incomodar", 
      "Fastidiar", 
      "Irritar", 
      "Acosar", 
      "Perturbar",
    ],
    respuestasCorrectas: ["Molestar"],
    fonetica: ["ˈbɑðər"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Breakfast",
    opciones: [
      "Desayunar", 
      "Almorzar", 
      "Comer", 
      "Cenar", 
      "Meriendar", 
      "Ayunar",
    ],
    respuestasCorrectas: ["Desayunar"],
    fonetica: ["ˈbrɛkfəst"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Breathe",
    opciones: [
      "Respirar", 
      "Inhalar", 
      "Exhalar", 
      "Aspirar", 
      "Tomar aire", 
      "Ventilar",
    ],
    respuestasCorrectas: ["Respirar"],
    fonetica: ["brið"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Brush",
    opciones: [
      "Cepillar", 
      "Peinar", 
      "Frotar", 
      "Limpiar", 
      "Desenredar", 
      "Alisar",
    ],
    respuestasCorrectas: ["Cepillar"],
    fonetica: ["brʌʃ"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Burn",
    opciones: [
      "Quemar", 
      "Arder", 
      "Incendiar", 
      "Calcinar", 
      "Chamuscar", 
      "Carbonizar",
    ],
    respuestasCorrectas: ["Quemar"],
    fonetica: ["bɜrn"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Call",
    opciones: [
      "Llamar", 
      "Telefonear", 
      "Marcar", 
      "Contactar", 
      "Comunicar", 
      "Avisar",
    ],
    respuestasCorrectas: ["Llamar"],
    fonetica: ["kɔl"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Cancel",
    opciones: [
      "Cancelar", 
      "Anular", 
      "Suspender", 
      "Revocar", 
      "Abortar", 
      "Invalidar",
    ],
    respuestasCorrectas: ["Cancelar"],
    fonetica: ["ˈkænsəl"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Care",
    opciones: [
      "Preocuparse", 
      "Cuidar", 
      "Atender", 
      "Proteger", 
      "Vigilar", 
      "Asistir",
    ],
    respuestasCorrectas: ["Preocuparse"],
    fonetica: ["kɛr"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Carry",
    opciones: [
      "Cargar", 
      "Transportar", 
      "Llevar", 
      "Mover", 
      "Trasladar", 
      "Acercar",
    ],
    respuestasCorrectas: ["Cargar"],
    fonetica: ["ˈkæri"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Cause",
    opciones: [
      "Causar", 
      "Provocar", 
      "Generar", 
      "Originar", 
      "Producir", 
      "Motivar",
    ],
    respuestasCorrectas: ["Causar"],
    fonetica: ["kɑz"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Change",
    opciones: [
      "Cambiar", 
      "Modificar", 
      "Alterar", 
      "Transformar", 
      "Variar", 
      "Reemplazar",
    ],
    respuestasCorrectas: ["Cambiar"],
    fonetica: ["ʧeɪnʤ"],
  },
];