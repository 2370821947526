export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Arise",
    opciones: [
      "Surgir", 
      "Emerger", 
      "Aparecer", 
      "Levantarse", 
      "Manifestarse", 
      "Nacer",
    ],
    respuestasCorrectas: ["Surgir"],
    fonetica: ["əˈraɪz"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Be",
    opciones: [
      "Ser", 
      "Estar", 
      "Existir", 
      "Permanecer", 
      "Vivir", 
      "Tener",
    ],
    respuestasCorrectas: ["Ser"],
    fonetica: ["bi"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Beat",
    opciones: [
      "Golpear", 
      "Latir", 
      "Batir", 
      "Pegar", 
      "Vencer", 
      "Aplastar",
    ],
    respuestasCorrectas: ["Golpear"],
    fonetica: ["bit"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Become",
    opciones: [
      "Convertirse", 
      "Llegar a ser", 
      "Transformarse", 
      "Volverse", 
      "Hacerse", 
      "Evolucionar",
    ],
    respuestasCorrectas: ["Convertirse"],
    fonetica: ["bɪˈkʌm"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Begin",
    opciones: [
      "Comenzar", 
      "Empezar", 
      "Iniciar", 
      "Dar inicio", 
      "Echar a andar", 
      "Poner en marcha",
    ],
    respuestasCorrectas: ["Comenzar"],
    fonetica: ["bɪˈɡɪn"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Bend",
    opciones: [
      "Doblar", 
      "Flexionar", 
      "Inclinar", 
      "Torcer", 
      "Curvar", 
      "Arquear",
    ],
    respuestasCorrectas: ["Doblar"],
    fonetica: ["bɛnd"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Bite",
    opciones: [
      "Morder", 
      "Masticar", 
      "Hincar el diente", 
      "Picotear", 
      "Clavar los dientes", 
      "Engancharse",
    ],
    respuestasCorrectas: ["Morder"],
    fonetica: ["baɪt"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Bleed",
    opciones: [
      "Sangrar", 
      "Hemorragiar", 
      "Derramar sangre", 
      "Desangrarse", 
      "Perder sangre", 
      "Exudar",
    ],
    respuestasCorrectas: ["Sangrar"],
    fonetica: ["blid"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Blow",
    opciones: [
      "Soplar", 
      "Respirar", 
      "Exhalar", 
      "Echar viento", 
      "Bofetear", 
      "Desinflar",
    ],
    respuestasCorrectas: ["Soplar"],
    fonetica: ["bloʊ"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Break",
    opciones: [
      "Romper", 
      "Quebrar", 
      "Partir", 
      "Rasgar", 
      "Deshacer", 
      "Dividir",
    ],
    respuestasCorrectas: ["Romper"],
    fonetica: ["breɪk"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Breed",
    opciones: [
      "Criar", 
      "Engendrar", 
      "Reproducir", 
      "Cuidar", 
      "Procrear", 
      "Alimentar",
    ],
    respuestasCorrectas: ["Criar"],
    fonetica: ["brid"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Bring",
    opciones: [
      "Traer", 
      "Llevar", 
      "Transportar", 
      "Acercar", 
      "Conducir", 
      "Portar",
    ],
    respuestasCorrectas: ["Traer"],
    fonetica: ["brɪŋ"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Build",
    opciones: [
      "Construir", 
      "Edificar", 
      "Levantar", 
      "Erigir", 
      "Fabricar", 
      "Montar",
    ],
    respuestasCorrectas: ["Construir"],
    fonetica: ["bɪld"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Burst",
    opciones: [
      "Reventar/Estallar", 
      "Explotar", 
      "Romper", 
      "Despedazar", 
      "Quebrar", 
      "Hacer añicos",
    ],
    respuestasCorrectas: ["Reventar/Estallar"],
    fonetica: ["bɜrst"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Buy",
    opciones: [
      "Comprar", 
      "Adquirir", 
      "Obtener", 
      "Mercar", 
      "Conseguir", 
      "Procurar",
    ],
    respuestasCorrectas: ["Comprar"],
    fonetica: ["baɪ"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Cast",
    opciones: [
      "Tirar/Arrojar", 
      "Lanzar", 
      "Echar", 
      "Despedir", 
      "Proyectar", 
      "Emitir",
    ],
    respuestasCorrectas: ["Tirar/Arrojar"],
    fonetica: ["kæst"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Catch",
    opciones: [
      "Cachar", 
      "Atrapar", 
      "Capturar", 
      "Agarrar", 
      "Tomar", 
      "Asir",
    ],
    respuestasCorrectas: ["Cachar"],
    fonetica: ["kæʧ"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Choose",
    opciones: [
      "Escoger/Elegir", 
      "Seleccionar", 
      "Optar", 
      "Preferir", 
      "Designar", 
      "Determinar",
    ],
    respuestasCorrectas: ["Escoger/Elegir"],
    fonetica: ["ʧuz"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Cling",
    opciones: [
      "Aferrarse", 
      "Adherirse", 
      "Pegarse", 
      "Abrazarse", 
      "Sujetar", 
      "Enlazarse",
    ],
    respuestasCorrectas: ["Aferrarse"],
    fonetica: ["klɪŋ"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Come",
    opciones: [
      "Venir", 
      "Llegar", 
      "Aparecer", 
      "Acudir", 
      "Presentarse", 
      "Arribar",
    ],
    respuestasCorrectas: ["Venir"],
    fonetica: ["kʌm"],
  },
];