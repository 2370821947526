import React from "react";
import { EstructuraCuestionario } from "data/setUpQuizzes/questionnaireStructure.js";
import { preguntasIniciales } from "../wordsIrregular/l6.js";
import "verbs/lessons.css"

const tituloLeccion = "Lección 6: Verbo Irregular";
const linkSiguiente = "/verb-irregular/leccion-6-p2";



function Leccion6VI() {
  return (
    <EstructuraCuestionario
      tituloLeccion={tituloLeccion}
      linkSiguiente={linkSiguiente}
      preguntasIniciales={preguntasIniciales}
    />
  );
}

export default Leccion6VI;
