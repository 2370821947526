
import { Link } from "react-router-dom";
import "verbs/regular/regularHome.css";

const lecciones = [
  { id: 1, titulo: "LECCIÓN 1", preguntas: 20 },
  { id: 2, titulo: "LECCIÓN 2", preguntas: 20 },
  { id: 3, titulo: "LECCIÓN 3", preguntas: 20 },
  { id: 4, titulo: "LECCIÓN 4", preguntas: 20 },
  { id: 5, titulo: "LECCIÓN 5", preguntas: 20 },
  { id: 6, titulo: "LECCIÓN 6", preguntas: 20 },
  { id: 7, titulo: "LECCIÓN 7", preguntas: 20 },
  { id: 8, titulo: "LECCIÓN 8", preguntas: 11 },
];

function Animals() {
  
  return (
    <div className="inicio">
      {/* Título agregado para identificar que estamos en el verbo regular */}
      <div className="contenedor-titulo">
        <p className="titulo-verbo-regular">Animals</p>
      </div>
      {lecciones.map((leccion) => (
        <Link
          className="modulo lecciones"
          to={`/vocabulary/animals/leccion-${leccion.id}`}
          key={leccion.id}
        >
          <p className="titulo">{leccion.titulo}</p>
          <p className="contenido">Compuesto por {leccion.preguntas} preguntas</p>
        </Link>
      ))}
    </div>
  );
}

export default Animals;
