export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Describe",
    opciones: [
      "Describir", 
      "Explicar", 
      "Relatar", 
      "Narrar", 
      "Detallar", 
      "Enumerar",
    ],
    respuestasCorrectas: ["Describir"],
    fonetica: ["dɪˈskraɪb"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Design",
    opciones: [
      "Diseñar", 
      "Crear", 
      "Concebir", 
      "Esquematizar", 
      "Planificar", 
      "Proyectar",
    ],
    respuestasCorrectas: ["Diseñar"],
    fonetica: ["dɪˈzaɪn"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Dial",
    opciones: [
      "Marcar (teléfono)", 
      "Discar", 
      "Telefonar", 
      "Llamar", 
      "Pulsar", 
      "Conectar",
    ],
    respuestasCorrectas: ["Marcar (teléfono)"],
    fonetica: ["ˈdaɪəl"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Die",
    opciones: [
      "Morir", 
      "Fallecer", 
      "Expirar", 
      "Sucumbir", 
      "Perecer", 
      "Desaparecer",
    ],
    respuestasCorrectas: ["Morir"],
    fonetica: ["daɪ"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Dine",
    opciones: [
      "Cenar", 
      "Comer", 
      "Almorzar", 
      "Banquetear", 
      "Degustar", 
      "Cenarse",
    ],
    respuestasCorrectas: ["Cenar"],
    fonetica: ["daɪn"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Discover",
    opciones: [
      "Descubrir", 
      "Encontrar", 
      "Revelar", 
      "Desvelar", 
      "Detectar", 
      "Hallarse",
    ],
    respuestasCorrectas: ["Descubrir"],
    fonetica: ["dɪˈskʌvər"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Discuss",
    opciones: [
      "Discutir", 
      "Debatir", 
      "Conversar", 
      "Dialogar", 
      "Hablar", 
      "Examinar",
    ],
    respuestasCorrectas: ["Discutir"],
    fonetica: ["dɪˈskʌs"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Dream",
    opciones: [
      "Soñar", 
      "Imaginar", 
      "Fantasear", 
      "Ilusionar", 
      "Anhelar", 
      "Desear",
    ],
    respuestasCorrectas: ["Soñar"],
    fonetica: ["drim"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Drop",
    opciones: [
      "Dejar caer", 
      "Soltar", 
      "Caer", 
      "Abandonar", 
      "Descender", 
      "Tirar",
    ],
    respuestasCorrectas: ["Dejar caer"],
    fonetica: ["drɑp"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Dry",
    opciones: [
      "Secar", 
      "Deshidratar", 
      "Absorber", 
      "Evaporar", 
      "Desecar", 
      "Escurrir",
    ],
    respuestasCorrectas: ["Secar"],
    fonetica: ["draɪ"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Earn",
    opciones: [
      "Ganarse", 
      "Obtener", 
      "Conseguir", 
      "Adquirir", 
      "Percibir", 
      "Recaudar",
    ],
    respuestasCorrectas: ["Ganarse"],
    fonetica: ["ɜrn"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Enjoy",
    opciones: [
      "Disfrutar", 
      "Gozar", 
      "Divertirse", 
      "Saborear", 
      "Apreciar", 
      "Deleitarse",
    ],
    respuestasCorrectas: ["Disfrutar"],
    fonetica: ["ɛnˈʤɔɪ"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Enter",
    opciones: [
      "Entrar", 
      "Acceder", 
      "Ingresar", 
      "Penetrar", 
      "Introducirse", 
      "Adentrarse",
    ],
    respuestasCorrectas: ["Entrar"],
    fonetica: ["ˈɛntər"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Excuse",
    opciones: [
      "Disculpar", 
      "Perdonar", 
      "Exculpar", 
      "Justificar", 
      "Redimir", 
      "Absolver",
    ],
    respuestasCorrectas: ["Disculpar"],
    fonetica: ["ɪkˈskjus"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Expect",
    opciones: [
      "Esperar", 
      "Anticipar", 
      "Prever", 
      "Aguardar", 
      "Desear", 
      "Pronosticar",
    ],
    respuestasCorrectas: ["Esperar"],
    fonetica: ["ɪkˈspɛkt"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Explain",
    opciones: [
      "Explicar", 
      "Elucidar", 
      "Describir", 
      "Aclarar", 
      "Especificar", 
      "Interpretar",
    ],
    respuestasCorrectas: ["Explicar"],
    fonetica: [""],
  },
  {
    id: 17,
    texto: '',
    palabra: "Express",
    opciones: [
      "Expresar", 
      "Manifestar", 
      "Comunicar", 
      "Transmitir", 
      "Declarar", 
      "Revelar",
    ],
    respuestasCorrectas: ["Expresar"],
    fonetica: ["ɪkˈspleɪn"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Face",
    opciones: [
      "Enfrentar", 
      "Afrontar", 
      "Confrontar", 
      "Desafiar", 
      "Plantarse", 
      "Encajar",
    ],
    respuestasCorrectas: ["Enfrentar"],
    fonetica: ["feɪs"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Fail",
    opciones: [
      "Fracasar", 
      "Fallar", 
      "Colapsar", 
      "Desmoronarse", 
      "Decepcionar", 
      "Desplomarse",
    ],
    respuestasCorrectas: ["Fracasar"],
    fonetica: ["feɪl"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Fear",
    opciones: [
      "Temer", 
      "Asustar", 
      "Aterrarse", 
      "Intimidarse", 
      "Amedrentarse", 
      "Espantarse",
    ],
    respuestasCorrectas: ["Temer"],
    fonetica: ["fɪr"],
  },
];