export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Doctor",
    opciones: [
      "Médico", 
      "Enfermero", 
      "Psicólogo", 
      "Fisioterapeuta", 
      "Odontólogo", 
      "Cirujano",
    ],
    respuestasCorrectas: ["Médico"],
    fonetica: ["ˈdɑktər"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Nurse",
    opciones: [
      "Enfermero", 
      "Médico", 
      "Psicólogo", 
      "Fisioterapeuta", 
      "Odontólogo", 
      "Cirujano",
    ],
    respuestasCorrectas: ["Enfermero"],
    fonetica: ["nɜrs"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Psychologist",
    opciones: [
      "Psicólogo", 
      "Médico", 
      "Enfermero", 
      "Fisioterapeuta", 
      "Odontólogo", 
      "Cirujano",
    ],
    respuestasCorrectas: ["Psicólogo"],
    fonetica: ["saɪˈkɑləʤəst"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Physiotherapist",
    opciones: [
      "Fisioterapeuta", 
      "Psicólogo", 
      "Médico", 
      "Enfermero", 
      "Odontólogo", 
      "Cirujano",
    ],
    respuestasCorrectas: ["Fisioterapeuta"],
    fonetica: ["Physiotherapist"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Dentist",
    opciones: [
      "Odontólogo", 
      "Médico", 
      "Enfermero", 
      "Psicólogo", 
      "Fisioterapeuta", 
      "Cirujano",
    ],
    respuestasCorrectas: ["Odontólogo"],
    fonetica: ["ˈdɛntəst"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Nutritionist",
    opciones: [
      "Nutricionista", 
      "Dietista", 
      "Médico", 
      "Enfermero", 
      "Psicólogo", 
      "Fisioterapeuta",
    ],
    respuestasCorrectas: ["Nutricionista"],
    fonetica: ["nuˈtrɪʃənɪst"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Radiologist",
    opciones: [
      "Radiólogo", 
      "Farmacéutico", 
      "Enfermero", 
      "Médico", 
      "Fisioterapeuta", 
      "Psicólogo",
    ],
    respuestasCorrectas: ["Radiólogo"],
    fonetica: ["ˌreɪdiˈɑləʤɪst"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Pharmacist",
    opciones: [
      "Farmacéutico", 
      "Radiólogo", 
      "Médico", 
      "Psicólogo", 
      "Fisioterapeuta", 
      "Enfermero",
    ],
    respuestasCorrectas: ["Farmacéutico"],
    fonetica: ["ˈfɑrməsɪst"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Veterinarian",
    opciones: [
      "Veterinario", 
      "Médico", 
      "Enfermero", 
      "Farmacéutico", 
      "Radiólogo", 
      "Psicólogo",
    ],
    respuestasCorrectas: ["Veterinario"],
    fonetica: ["ˌvɛtrəˈnɛriən"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Window cleaner",
    opciones: [
      "Profesor", 
      "Docente", 
      "Catedrático", 
      "Maestro", 
      "Instructor", 
      "Tutor",
    ],
    respuestasCorrectas: ["limpiador de ventanas"],
    fonetica: ["ˈwɪndoʊ ˈklinər"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Teacher",
    opciones: [
      "Maestro", 
      "Profesor", 
      "Docente", 
      "Instructor", 
      "Tutor", 
      "Educador",
    ],
    respuestasCorrectas: ["Maestro"],
    fonetica: ["ˈtiʧər"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Pedagogue",
    opciones: [
      "Pedagogo", 
      "Docente", 
      "Educador", 
      "Maestro", 
      "Tutor", 
      "Instructor",
    ],
    respuestasCorrectas: ["Pedagogo"],
    fonetica: ["Pedagogue"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Children's educator",
    opciones: [
      "Educador infantil", 
      "Docente de primera infancia", 
      "Maestro preescolar", 
      "Instructor de párvulos", 
      "Pedagogo infantil", 
      "Profesor de jardín de niños",
    ],
    respuestasCorrectas: ["Educador infantil"],
    fonetica: ["ˈʧɪldrənz ˈɛʤəˌkeɪtər"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Academic Advisor",
    opciones: [
      "Orientador académico", 
      "Consejero educativo", 
      "Asesor académico", 
      "Tutor académico", 
      "Guía educativo", 
      "Consultor educativo",
    ],
    respuestasCorrectas: ["Orientador académico"],
    fonetica: ["ˌækəˈdɛmɪk ædˈvaɪzər"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Software Developer",
    opciones: [
      "Desarrollador de software", 
      "Programador", 
      "Ingeniero de software", 
      "Analista de sistemas", 
      "Especialista en TI", 
      "Arquitecto de software",
    ],
    respuestasCorrectas: ["Desarrollador de software"],
    fonetica: ["ˈsɔfˌtwɛr dɪˈvɛləpər"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Systems Engineer",
    opciones: [
      "Ingeniero de sistemas", 
      "Administrador de redes", 
      "Especialista en ciberseguridad", 
      "Diseñador UX/UI", 
      "Científico de datos", 
      "Desarrollador de software",
    ],
    respuestasCorrectas: ["Ingeniero de sistemas"],
    fonetica: ["ˈsɪstəmz ˈɛnʤəˈnɪr"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Network Administrator",
    opciones: [
      "Administrador de redes", 
      "Ingeniero de sistemas", 
      "Especialista en ciberseguridad", 
      "Diseñador UX/UI", 
      "Científico de datos", 
      "Desarrollador de software",
    ],
    respuestasCorrectas: ["Administrador de redes"],
    fonetica: ["ˈnɛˌtwɜrk ədˈmɪnəˌstreɪtər"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Cybersecurity Specialist",
    opciones: [
      "Especialista en ciberseguridad", 
      "Ingeniero de sistemas", 
      "Administrador de redes", 
      "Diseñador UX/UI", 
      "Científico de datos", 
      "Desarrollador de software",
    ],
    respuestasCorrectas: ["Especialista en ciberseguridad"],
    fonetica: ["Cybersecurity ˈspɛʃələst"],
  },
  {
    id: 19,
    texto: '',
    palabra: "UX/UI Designer",
    opciones: [
      "Diseñador UX/UI", 
      "Ingeniero de sistemas", 
      "Administrador de redes", 
      "Especialista en ciberseguridad", 
      "Científico de datos", 
      "Desarrollador de software",
    ],
    respuestasCorrectas: ["Diseñador UX/UI"],
    fonetica: ["ju-ɛks/ju-aɪ dɪˈzaɪnər"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Data Scientist",
    opciones: [
      "Científico de datos", 
      "Ingeniero de sistemas", 
      "Administrador de redes", 
      "Especialista en ciberseguridad", 
      "Diseñador UX/UI", 
      "Desarrollador de software",
    ],
    respuestasCorrectas: ["Científico de datos"],
    fonetica: ["ˈdeɪtə ˈsaɪəntɪst"],
  },
];