export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Blue",
    opciones: [
      "Azul", 
      "Cian", 
      "Marino", 
      "Azul cielo", 
      "Verde azulado", 
      "Turquesa",
    ],
    respuestasCorrectas: ["Azul"],
    fonetica: ["blu"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Red",
    opciones: [
      "Rojo", 
      "Granate", 
      "Carmesí", 
      "Escarlata", 
      "Burdeos", 
      "Rojo cereza",
    ],
    respuestasCorrectas: ["Rojo"],
    fonetica: ["rɛd"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Yellow",
    opciones: [
      "Amarillo", 
      "Dorado", 
      "Limón", 
      "Mostaza", 
      "Canario", 
      "Ámbar",
    ],
    respuestasCorrectas: ["Amarillo"],
    fonetica: ["ˈjɛloʊ"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Green",
    opciones: [
      "Verde", 
      "Lima", 
      "Oliva", 
      "Esmeralda", 
      "Menta", 
      "Jade",
    ],
    respuestasCorrectas: ["Verde"],
    fonetica: ["ɡrin"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Orange",
    opciones: [
      "Naranja", 
      "Melocotón", 
      "Albaricoque", 
      "Coral", 
      "Mandarina", 
      "Ámbar",
    ],
    respuestasCorrectas: ["Naranja"],
    fonetica: ["ˈɔrənʤ"],
  },  
  {
    id: 6,
    texto: '',
    palabra: "Purple",
    opciones: [
      "Morado", 
      "Lavanda", 
      "Violeta", 
      "Lila", 
      "Ciruela", 
      "Malva",
    ],
    respuestasCorrectas: ["Morado"],
    fonetica: ["ˈpɜrpəl"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Pink",
    opciones: [
      "Rosa", 
      "Rosado", 
      "Fucsia", 
      "Salmón", 
      "Rubor", 
      "Coral",
    ],
    respuestasCorrectas: ["Rosa"],
    fonetica: ["pɪŋk"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Brown",
    opciones: [
      "Marrón", 
      "Beige", 
      "Chocolate", 
      "Café", 
      "Bronceado", 
      "Espresso",
    ],
    respuestasCorrectas: ["Marrón"],
    fonetica: ["braʊn"],
  },
  {
    id: 9,
    texto: '',
    palabra: "White",
    opciones: [
      "Blanco", 
      "Marfil", 
      "Nieve", 
      "Crema", 
      "Perla", 
      "Alabastro",
    ],
    respuestasCorrectas: ["Blanco"],
    fonetica: ["waɪt"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Black",
    opciones: [
      "Negro", 
      "Azabache", 
      "Ébano", 
      "Ónix", 
      "Carbón", 
      "Hulla",
    ],
    respuestasCorrectas: ["Negro"],
    fonetica: ["blæk"],
  },  
  {
    id: 11,
    texto: '',
    palabra: "Gray",
    opciones: [
      "Gris", 
      "Carbón", 
      "Pizarra", 
      "Plateado", 
      "Ceniza", 
      "Peltre",
    ],
    respuestasCorrectas: ["Gris"],
    fonetica: ["ɡreɪ"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Beige",
    opciones: [
      "Beige", 
      "Ante", 
      "Camello", 
      "Crudo", 
      "Bayo", 
      "Bronceado",
    ],
    respuestasCorrectas: ["Beige"],
    fonetica: ["beɪʒ"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Sky Blue",
    opciones: [
      "Celeste", 
      "Azul bebé", 
      "Azul polvo", 
      "Azul claro", 
      "Azur", 
      "Azul hielo",
    ],
    respuestasCorrectas: ["Celeste"],
    fonetica: ["skaɪ blu"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Turquoise",
    opciones: [
      "Turquesa", 
      "Verde azulado", 
      "Aguamarina", 
      "Cian", 
      "Verde agua", 
      "Azul Tiffany",
    ],
    respuestasCorrectas: ["Turquesa"],
    fonetica: ["ˈtɜrkwɔɪz"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Lilac",
    opciones: [
      "Lila", 
      "Lavanda", 
      "Azul pervinca", 
      "Orquídea", 
      "Cardo", 
      "Heliotropo",
    ],
    respuestasCorrectas: ["Lila"],
    fonetica: ["ˈlaɪˌlæk"],
  },  
  {
    id: 16,
    texto: '',
    palabra: "Coral",
    opciones: [
      "Coral", 
      "Salmón", 
      "Durazno", 
      "Albaricoque", 
      "Melón", 
      "Mandarina",
    ],
    respuestasCorrectas: ["Coral"],
    fonetica: ["ˈkɔrəl"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Violet",
    opciones: [
      "Violeta", 
      "Lavanda", 
      "Morado", 
      "Malva", 
      "Orquídea", 
      "Ciruela",
    ],
    respuestasCorrectas: ["Violeta"],
    fonetica: ["ˈvaɪəlɪt"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Lime",
    opciones: [
      "Lima", 
      "Chartreuse", 
      "Verde manzana", 
      "Menta", 
      "Oliva", 
      "Verde neón",
    ],
    respuestasCorrectas: ["Lima"],
    fonetica: ["laɪm"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Aqua",
    opciones: [
      "Aqua", 
      "Cian", 
      "Turquesa", 
      "Verde azulado", 
      "Menta", 
      "Azul huevo de petirrojo",
    ],
    respuestasCorrectas: ["Aqua"],
    fonetica: ["ˈækwə"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Indigo",
    opciones: [
      "Índigo", 
      "Marino", 
      "Azul medianoche", 
      "Azul real", 
      "Cobalto", 
      "Zafiro",
    ],
    respuestasCorrectas: ["Índigo"],
    fonetica: ["ˈɪndəˌɡoʊ"],
  },  
];