export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Stroke",
    opciones: [
      "Acariciar", 
      "Palpar", 
      "Rozar", 
      "Frotar suavemente", 
      "Acariciar con la mano", 
      "Tocar suavemente",
    ],
    respuestasCorrectas: ["Acariciar"],
    fonetica: ["stroʊk"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Study",
    opciones: [
      "Estudiar", 
      "Analizar", 
      "Investigar", 
      "Examinar", 
      "Revisar", 
      "Observar",
    ],
    respuestasCorrectas: ["Estudiar"],
    fonetica: ["ˈstʌdi"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Suggest",
    opciones: [
      "Sugerir", 
      "Proponer", 
      "Recomendar", 
      "Indicar", 
      "Aconsejar", 
      "Insinuar",
    ],
    respuestasCorrectas: ["Sugerir"],
    fonetica: ["səɡˈʤɛst"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Support",
    opciones: [
      "Apoyar", 
      "Respaldar", 
      "Sostener", 
      "Ayudar", 
      "Brindar soporte", 
      "Secundar",
    ],
    respuestasCorrectas: ["Apoyar"],
    fonetica: ["səˈpɔrt"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Suppose",
    opciones: [
      "Suponer", 
      "Asumir", 
      "Presumir", 
      "Imaginar", 
      "Considerar", 
      "Inferir",
    ],
    respuestasCorrectas: ["Suponer"],
    fonetica: ["səˈpoʊz"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Surprise",
    opciones: [
      "Sorprender", 
      "Asombrar", 
      "Admirar", 
      "Desconcertar", 
      "Impresionar", 
      "Maravillar",
    ],
    respuestasCorrectas: ["Sorprender"],
    fonetica: ["sərˈpraɪz"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Sweat",
    opciones: [
      "Sudar", 
      "Transpirar", 
      "Empaparse", 
      "Mojarse", 
      "Exudar", 
      "Segregar",
    ],
    respuestasCorrectas: ["Sudar"],
    fonetica: ["swɛt"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Talk",
    opciones: [
      "Hablar", 
      "Conversar", 
      "Platicar", 
      "Dialogar", 
      "Charlar", 
      "Comunicar",
    ],
    respuestasCorrectas: ["Hablar"],
    fonetica: ["tɔk"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Tie",
    opciones: [
      "Amarrar", 
      "Atar", 
      "Ligar", 
      "Enlazar", 
      "Unir", 
      "Anudar",
    ],
    respuestasCorrectas: ["Amarrar"],
    fonetica: ["taɪ"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Travel",
    opciones: [
      "Viajar", 
      "Desplazarse", 
      "Moverse", 
      "Recorrer", 
      "Ir", 
      "Trasladarse",
    ],
    respuestasCorrectas: ["Viajar"],
    fonetica: ["ˈtrævəl"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Treat",
    opciones: [
      "Tratar/Convidar", 
      "Atender", 
      "Curar", 
      "Manejar", 
      "Cuidar", 
      "Invitar",
    ],
    respuestasCorrectas: ["Tratar/Convidar"],
    fonetica: ["trit"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Try",
    opciones: [
      "Probar/Intentar", 
      "Ensayar", 
      "Experimentar", 
      "Evaluar", 
      "Poner a prueba", 
      "Procurar",
    ],
    respuestasCorrectas: ["Probar/Intentar"],
    fonetica: ["Try"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Turn",
    opciones: [
      "Girar/Convertir", 
      "Voltear", 
      "Rotar", 
      "Cambiar de dirección", 
      "Virar", 
      "Transformar",
    ],
    respuestasCorrectas: ["Girar/Convertir"],
    fonetica: ["tɜrn"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Use",
    opciones: [
      "Usar", 
      "Utilizar", 
      "Emplear", 
      "Aplicar", 
      "Aprovechar", 
      "Servirse de",
    ],
    respuestasCorrectas: ["Usar"],
    fonetica: ["juz"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Visit",
    opciones: [
      "Visitar", 
      "Ir a ver", 
      "Reunirse con", 
      "Recorrer", 
      "Explorar", 
      "Acudir",
    ],
    respuestasCorrectas: ["Visitar"],
    fonetica: ["ˈvɪzət"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Wait",
    opciones: [
      "Esperar", 
      "Aguardar", 
      "Detenerse", 
      "Quedarse", 
      "Tener paciencia", 
      "Permanecer",
    ],
    respuestasCorrectas: ["Esperar"],
    fonetica: ["ˈvɪzət"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Walk",
    opciones: [
      "Caminar", 
      "Andar", 
      "Pasear", 
      "Marchar", 
      "Desplazarse", 
      "Recorrer",
    ],
    respuestasCorrectas: ["Caminar"],
    fonetica: ["wɔk"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Want",
    opciones: [
      "Querer", 
      "Desear", 
      "Anhelar", 
      "Necesitar", 
      "Preferir", 
      "Aspirar a",
    ],
    respuestasCorrectas: ["Querer"],
    fonetica: ["wɑnt"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Wash",
    opciones: [
      "Lavar", 
      "Limpiar", 
      "Fregar", 
      "Enjuagar", 
      "Desinfectar", 
      "Asear",
    ],
    respuestasCorrectas: ["Lavar"],
    fonetica: ["wɑʃ"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Watch",
    opciones: [
      "Ver/Mirar", 
      "Observar", 
      "Contemplar", 
      "Vigilar", 
      "Atender", 
      "Examinar",
    ],
    respuestasCorrectas: ["Ver/Mirar"],
    fonetica: ["wɑʧ"],
  },
];