import React from "react";
import { EstructuraCuestionario } from "data/setUpQuizzes/questionnaireStructure";
import { preguntasIniciales } from "../wordsProfessions/l4";
import "verbs/lessons.css"

const tituloLeccion = "Lección 4: Professions";
const linkSiguiente = "/vocabulary/professions/leccion-4-p2";



function Leccion4Professions() {
  return (
    <EstructuraCuestionario
      tituloLeccion={tituloLeccion}
      linkSiguiente={linkSiguiente}
      preguntasIniciales={preguntasIniciales}
    />
  );
}

export default Leccion4Professions;

