export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Nun",
    opciones: [
      "Monja", 
      "Religiosa", 
      "Hermana", 
      "Sor", 
      "Novicia", 
      "Clarisas",
    ],
    respuestasCorrectas: ["Monja"],
    fonetica: ["nʌn"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Optician",
    opciones: [
      "Óptico", 
      "Optometrista", 
      "Especialista en lentes", 
      "Oftalmólogo", 
      "Tecnólogo óptico", 
      "Consultor de visión",
    ],
    respuestasCorrectas: ["Óptico"],
    fonetica: ["ɑpˈtɪʃən"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Painter",
    opciones: [
      "Pintor", 
      "Artista", 
      "Decorador", 
      "Ilustrador", 
      "Colorista", 
      "Diseñador",
    ],
    respuestasCorrectas: ["Pintor"],
    fonetica: ["ˈpeɪntər"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Physicist",
    opciones: [
      "Físico", 
      "Científico", 
      "Investigador", 
      "Especialista en física", 
      "Profesor de física", 
      "Estudioso de la materia",
    ],
    respuestasCorrectas: ["Físico"],
    fonetica: ["ˈfɪzɪsɪst"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Real estate agent",
    opciones: [
      "Agente de bienes raíces", 
      "Agente inmobiliario", 
      "Corredor de propiedades", 
      "Consultor inmobiliario", 
      "Vendedor de bienes raíces", 
      "Intermediario de propiedades",
    ],
    respuestasCorrectas: ["Agente de bienes raíces"],
    fonetica: ["riəl ɪˈsteɪt ˈeɪʤənt"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Sailor",
    opciones: [
      "Marinero", 
      "Navegante", 
      "Capitán", 
      "Tripulante", 
      "Barquero", 
      "Timonel",
    ],
    respuestasCorrectas: ["Marinero"],
    fonetica: ["ˈseɪlər"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Salesman",
    opciones: [
      "Vendedor", 
      "Comerciante", 
      "Agente de ventas", 
      "Distribuidor", 
      "Mercader", 
      "Consultor de ventas",
    ],
    respuestasCorrectas: ["Vendedor"],
    fonetica: ["ˈseɪlzmən"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Shop Assistant",
    opciones: [
      "Asistente de ventas", 
      "Dependiente", 
      "Empleado de tienda", 
      "Cajero", 
      "Auxiliar de tienda", 
      "Encargado de mostrador",
    ],
    respuestasCorrectas: ["Asistente de ventas"],
    fonetica: ["ʃɑp əˈsɪstənt"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Singer",
    opciones: [
      "Cantante", 
      "Vocalista", 
      "Artista", 
      "Músico", 
      "Interprete", 
      "Solista",
    ],
    respuestasCorrectas: ["Cantante"],
    fonetica: ["ˈsɪŋər"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Sportsman",
    opciones: [
      "Deportista", 
      "Atleta", 
      "Jugador", 
      "Competidor", 
      "Corredor", 
      "Entrenador",
    ],
    respuestasCorrectas: ["Deportista"],
    fonetica: ["ˈspɔrtsmən"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Tailor",
    opciones: [
      "Sastre", 
      "Modista", 
      "Confeccionista", 
      "Costurero", 
      "Arreglador de ropa", 
      "Diseñador de modas",
    ],
    respuestasCorrectas: ["Sastre"],
    fonetica: ["ˈteɪlər"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Taxi driver",
    opciones: [
      "Conductor de taxi", 
      "Taxista", 
      "Chofer de taxi", 
      "Operador de taxi", 
      "Conductor de transporte", 
      "Chofer",
    ],
    respuestasCorrectas: ["Conductor de taxi"],
    fonetica: ["ˈtæksi ˈdraɪvər"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Translator",
    opciones: [
      "Traductor", 
      "Intérprete", 
      "Lingüista", 
      "Especialista en idiomas", 
      "Traductor de textos", 
      "Adaptador",
    ],
    respuestasCorrectas: ["Traductor"],
    fonetica: ["trænˈsleɪtər"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Astronaut",
    opciones: [
      "Astronauta", 
      "Cosmonauta", 
      "Explorador espacial", 
      "Ingeniero aeroespacial", 
      "Piloto de naves", 
      "Tripulante espacial",
    ],
    respuestasCorrectas: ["Astronauta"],
    fonetica: ["ˈæstrəˌnɑt"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Author",
    opciones: [
      "Autor", 
      "Escritor", 
      "Novelista", 
      "Poeta", 
      "Dramaturgo", 
      "Redactor",
    ],
    respuestasCorrectas: ["Autor"],
    fonetica: ["ˈɔθər"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Bus driver",
    opciones: [
      "Conductor de autobús", 
      "Chofer de bus", 
      "Operador de transporte", 
      "Conductor de bus urbano", 
      "Chofer de transporte público", 
      "Operador de bus",
    ],
    respuestasCorrectas: ["Conductor de autobús"],
    fonetica: ["bʌs ˈdraɪvər"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Butcher",
    opciones: [
      "Carnicero", 
      "Cortador de carne", 
      "Despostador", 
      "Proveedor de carne", 
      "Vendedor de carne", 
      "Especialista en carnes",
    ],
    respuestasCorrectas: ["Carnicero"],
    fonetica: ["ˈbʊʧər"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Cleaner",
    opciones: [
      "Limpiador", 
      "Aseador", 
      "Encargado de limpieza", 
      "Higienista", 
      "Mantenimiento", 
      "Operario de limpieza",
    ],
    respuestasCorrectas: ["Limpiador"],
    fonetica: ["ˈklinər"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Postman",
    opciones: [
      "Cartero", 
      "Mensajero", 
      "Repartidor de correo", 
      "Distribuidor de cartas", 
      "Funcionario postal", 
      "Operador de correo",
    ],
    respuestasCorrectas: ["Cartero"],
    fonetica: ["ˈpoʊstmən"],
  },
  {
    id: 20,
    texto: '',
    palabra: "mailman",
    opciones: [
      "Cartero", 
      "Mensajero", 
      "Repartidor de correo", 
      "Distribuidor de cartas", 
      "Funcionario postal", 
      "Operador de correo",
    ],
    respuestasCorrectas: ["Cartero"],
    fonetica: ["ˈmeɪlˌmæn"],
  },
  {
    id: 21,
    texto: '',
    palabra: "Researcher",
    opciones: [
      "Investigador", 
      "Científico", 
      "Analista", 
      "Estudioso", 
      "Académico", 
      "Examinador",
    ],
    respuestasCorrectas: ["Investigador"],
    fonetica: ["ˈrisərʧər"],
  },
];