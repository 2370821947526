import React from "react";
import { EstructuraCuestionarioP3 } from "data/setUpQuizzesP3/questionnaireStructureP3.js";
import { preguntasIniciales } from "../wordsTechnology/l5.js";
import "verbs/lessons.css"

const tituloLeccion = "Lección 5 P3: Technology";
const linkSiguiente = "/vocabulary/technology/leccion-6"; //primeraPractica



function Leccion5TechnologyP3() {
  return (
    <EstructuraCuestionarioP3
      tituloLeccion={tituloLeccion}
      linkSiguiente={linkSiguiente}
      preguntasIniciales={preguntasIniciales}
    />
  );
}

export default Leccion5TechnologyP3;
