import React from "react";
import { EstructuraCuestionarioP3 } from "data/setUpQuizzesP3/questionnaireStructureP3.js";
import { preguntasIniciales } from "../wordsTechnology/l6.js";
import "verbs/lessons.css"

const tituloLeccion = "Lección 6 P3: Technology";
const linkSiguiente = "/vocabulary/technology";



function Leccion6TechnologyP3() {
  return (
    <EstructuraCuestionarioP3
      tituloLeccion={tituloLeccion}
      linkSiguiente={linkSiguiente}
      preguntasIniciales={preguntasIniciales}
    />
  );
}

export default Leccion6TechnologyP3;
