export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Scare",
    opciones: [
      "Asustar", 
      "Aterrorizar", 
      "Espantar", 
      "Atemorizar", 
      "Sobresaltar", 
      "Alarmar",
    ],
    respuestasCorrectas: ["Asustar"],
    fonetica: ["skɛr"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Seem",
    opciones: [
      "Parecer", 
      "Aparentar", 
      "Lucir", 
      "Verse", 
      "Presentarse", 
      "Figurar",
    ],
    respuestasCorrectas: ["Parecer"],
    fonetica: ["sim"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Serve",
    opciones: [
      "Servir", 
      "Atender", 
      "Proveer", 
      "Ofrecer", 
      "Suministrar", 
      "Acompañar",
    ],
    respuestasCorrectas: ["Servir"],
    fonetica: ["sɜrv"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Shape",
    opciones: [
      "Moldear", 
      "Formar", 
      "Dar forma", 
      "Configurar", 
      "Estructurar", 
      "Modelar"
    ],
    respuestasCorrectas: ["Moldear"],
    fonetica: ["ʃeɪp"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Shave",
    opciones: [
      "Rasurar", 
      "Afeitar", 
      "Cortar", 
      "Depilar", 
      "Recortar", 
      "Limar",
    ],
    respuestasCorrectas: ["Rasurar"],
    fonetica: ["ʃeɪv"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Shop",
    opciones: [
      "Comprar", 
      "Adquirir", 
      "Adquirir", 
      "Adquirir", 
      "Hacer compras", 
      "Ir de compras",
    ],
    respuestasCorrectas: ["Comprar"],
    fonetica: ["ʃɑp"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Shut",
    opciones: [
      "Cerrar", 
      "Clausurar", 
      "Bloquear", 
      "Sellar", 
      "Encerrar", 
      "Trabar",
    ],
    respuestasCorrectas: ["Cerrar"],
    fonetica: ["ʃʌt"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Sign",
    opciones: [
      "Firmar", 
      "Suscribir", 
      "Signar", 
      "Rubricar", 
      "Autografiar", 
      "Marcar",
    ],
    respuestasCorrectas: ["Firmar"],
    fonetica: ["saɪn"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Slip",
    opciones: [
      "Deslizar", 
      "Resbalar", 
      "Patinar", 
      "Escabullirse", 
      "Rebalar", 
      "Escurrirse",
    ],
    respuestasCorrectas: ["Deslizar"],
    fonetica: ["slɪp"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Smell",
    opciones: [
      "Oler", 
      "Olfatear", 
      "Percibir", 
      "Detectar", 
      "Aromatizar", 
      "Aspirar",
    ],
    respuestasCorrectas: ["Oler"],
    fonetica: ["smɛl"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Smile",
    opciones: [
      "Sonreír", 
      "Reír", 
      "Reírse", 
      "Sonreírse", 
      "Mueca de felicidad", 
      "Mostrar los dientes",
    ],
    respuestasCorrectas: ["Sonreír"],
    fonetica: ["smaɪl"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Smoke",
    opciones: [
      "Fumar", 
      "Inhalar", 
      "Exhalar", 
      "Chupar", 
      "Consumir tabaco", 
      "Echar humo",
    ],
    respuestasCorrectas: ["Fumar"],
    fonetica: ["smoʊk"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Sound",
    opciones: [
      "Sonar", 
      "Resonar", 
      "Retumbar", 
      "Emitir sonido", 
      "Producir ruido", 
      "Vociferar"
    ],
    respuestasCorrectas: ["Sonar"],
    fonetica: ["saʊnd"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Spell",
    opciones: [
      "Deletrear", 
      "Soletrar", 
      "Escribir", 
      "Nombrar letra por letra", 
      "Leer en voz alta", 
      "Pronunciar letra por letra",
    ],
    respuestasCorrectas: ["Deletrear"],
    fonetica: ["spɛl"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Spill",
    opciones: [
      "Derramar", 
      "Verter", 
      "Esparcir", 
      "Desbordar", 
      "Tirar", 
      "Echar",
    ],
    respuestasCorrectas: ["Derramar"],
    fonetica: ["spɪl"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Stare",
    opciones: [
      "Mirar fijamente", 
      "Fijar la mirada", 
      "Observar con insistencia", 
      "Clavar los ojos", 
      "Contemplar fijamente", 
      "Mirar detenidamente",
    ],
    respuestasCorrectas: ["Mirar fijamente"],
    fonetica: ["stɛr"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Start",
    opciones: [
      "Comenzar", 
      "Iniciar", 
      "Empezar", 
      "Poner en marcha", 
      "Arrancar", 
      "Desencadenar",
    ],
    respuestasCorrectas: ["Comenzar"],
    fonetica: ["stɑrt"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Stay",
    opciones: [
      "Quedarse", 
      "Permanecer", 
      "Permanecer", 
      "Residenciarse", 
      "Estar", 
      "Permanecer quieto",
    ],
    respuestasCorrectas: ["Quedarse"],
    fonetica: ["steɪ"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Step",
    opciones: [
      "Pisar", 
      "Caminar", 
      "Andar", 
      "Marchar", 
      "Dar un paso", 
      "Avanzar",
    ],
    respuestasCorrectas: ["Pisar"],
    fonetica: ["stɛp"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Stop",
    opciones: [
      "Detener/Parar", 
      "Frenar", 
      "Suspender", 
      "Interrumpir", 
      "Dejar de", 
      "Cesarse",
    ],
    respuestasCorrectas: ["Detener/Parar"],
    fonetica: ["stɑp"],
  },
];