import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { usePreguntas } from "../setUpQuizzesP2/useQuestionsP2";
import { useRespuestas } from "../setUpQuizzesP2/useAnswersP2";
import { useResultados } from "../setUpQuizzesP2/useResultsP2";
import { speakWord } from "data/responsiveVoiceHelper";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import RepeatIcon from "@mui/icons-material/Repeat";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export function EstructuraCuestionarioP2({
  tituloLeccion,
  linkSiguiente,
  preguntasIniciales,
}) {
  const { preguntas, setPreguntas, barajarArray } =
    usePreguntas(preguntasIniciales);
  const { respuestasUsuario, manejarCambioTexto, setRespuestasUsuario } =
    useRespuestas();
  const {
    resultados,
    puntajeTotal,
    manejarEnvio,
    manejarRepetirCuestionario,
    cuestionarioKey,
  } = useResultados(
    preguntas,
    setPreguntas,
    barajarArray,
    setRespuestasUsuario
  );

  // Función para centrar el campo de texto en foco
  const centrarCampoTextoEnFoco = (e) => {
    const target = e.target;
    if (target.tagName === "INPUT" && target.type === "text") {
      setTimeout(() => {
        target.scrollIntoView({ behavior: "smooth", block: "center" });
      }, 300);
    }
  };

  // Agregar y remover el evento focusin
  useEffect(() => {
    window.addEventListener("focusin", centrarCampoTextoEnFoco);

    return () => {
      window.removeEventListener("focusin", centrarCampoTextoEnFoco);
    };
  }, []);

  return (
    <div className="lecciones_INGLES">
      <div className="introduccion">
        <p>{tituloLeccion}</p>
      </div>
      <div key={cuestionarioKey}>
        {preguntas.map((pregunta, index) => (
          <div
            id={`pregunta-${pregunta.id}`}
            key={pregunta.id}
            className="pregunta"
          >
            <div className="pregunta-header">
              <span>{index + 1}. Pregunta</span>
              <span>1 punto</span>
            </div>
            <div className="pregunta-texto">
              <p>How is the following word translated into Spanish?</p>
            </div>
            <div className="pregunta-palabra-leccion">
              <div>
              {pregunta.palabra}
              <p className="parrafo-fonetica">{pregunta.fonetica}</p>
              </div>
              <button
                onClick={() => speakWord(pregunta.palabra)}
                type="button"
                className="button-api"
              >
                🔊 Play
              </button>
            </div>
            <div className="respuesta-usuario">
              <input
                type="text"
                autoComplete="off"
                value={respuestasUsuario[`pregunta${pregunta.id}`] || ""}
                onChange={(e) =>
                  manejarCambioTexto(pregunta.id, e.target.value)
                }
                className="respuesta-input"
              />
            </div>
            {resultados && (
              <div
                className={`resultado ${
                  resultados[index]?.correcto ? "correcto" : "incorrecto"
                }`}
              >
                {resultados[index]?.correcto ? "Correcto" : "Incorrecto"}
              </div>
            )}
          </div>
        ))}
        <div className="finalizar-cuestionario">
          <Button
            variant="contained"
            endIcon={<SendIcon />}
            onClick={() => manejarEnvio(respuestasUsuario)}
            color="info"
          >
            Enviar
          </Button>
        </div>
        {resultados && (
          <div className="resultados">
            <div className="puntaje-total">
              Puntaje total: {puntajeTotal} / {preguntas.length} (
              {((puntajeTotal / preguntas.length) * 100).toFixed(2)}%)
            </div>

            <div className="acciones">
              <Button
                variant="contained"
                endIcon={<RepeatIcon />}
                onClick={manejarRepetirCuestionario}
                color="inherit"
              >
                Repetir
              </Button>
            </div>
            {puntajeTotal === preguntas.length && (
              <div className="mensaje-exito">
                <p>Intentalo de ¡nuevo!.</p>
                <br />
                <Button
                  endIcon={<NavigateNextIcon />}
                  component={Link}
                  to={linkSiguiente}
                  variant="contained"
                  color="success"
                >
                  Siguiente
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
