import React from 'react';
import { Link } from 'react-router-dom';
import './vocabulary.css';

// Función para desplazarse al inicio de la página
function scrollToTop() {
  window.scrollTo(0, 0);
}

function Vocabulary() {
  return (
    <div className="home-container">
      <div className="row">
        <Link to="/vocabulary/home" className="category" onClick={scrollToTop}>
          <img src="./img/home_512.png" alt="Home" />
          <p>Home</p>
        </Link>
        <Link to="/vocabulary/technology" className="category" onClick={scrollToTop}>
          <img src="./img/technology_512.png" alt="Technology" />
          <p>Technology</p>
        </Link>
      </div>
      <div className="row">
        <Link to="/vocabulary/food" className="category" onClick={scrollToTop}>
          <img src="./img/food_512.png" alt="Food" />
          <p>Food</p>
        </Link>
        <Link to="/vocabulary/family" className="category" onClick={scrollToTop}>
          <img src="./img/family_512.png" alt="Family" />
          <p>Family</p>
        </Link>
      </div>
      <div className="row">
        <Link to="/vocabulary/clothes" className="category" onClick={scrollToTop}>
          <img src="./img/clothes_512.png" alt="Clothes" />
          <p>Clothes</p>
        </Link>
        <Link to="/vocabulary/humanBody" className="category" onClick={scrollToTop}>
          <img src="./img/body_512.png" alt="Body" />
          <p>Human body</p>
        </Link>
      </div>
      <div className="row">
        <Link to="/vocabulary/colors" className="category" onClick={scrollToTop}>
          <img src="./img/colors_512.png" alt="Colors" />
          <p>Colors</p>
        </Link>
        <Link to="/vocabulary/sports" className="category" onClick={scrollToTop}>
          <img src="./img/sports_512.png" alt="Sports" />
          <p>Sports</p>
        </Link>
      </div>
      <div className="row">
        <Link to="/vocabulary/professions" className="category" onClick={scrollToTop}>
          <img src="./img/professions_512.png" alt="Professions" />
          <p>Professions</p>
        </Link>
        <Link to="/vocabulary/animals" className="category" onClick={scrollToTop}>
          <img src="./img/animals_512.png" alt="Animals" />
          <p>Animals</p>
        </Link>
      </div>
    </div>
  );
}

export default Vocabulary;
