import React from 'react';
import { createRoot } from 'react-dom/client'; // Importa createRoot desde react-dom/client

import './App.css'



import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);
