export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Accept",
    opciones: [
      "Aceptar", 
      "Rechazar", 
      "Negar", 
      "Permitir", 
      "Consentir", 
      "Admitir"
    ],
    respuestasCorrectas: ["Aceptar"],
    fonetica: ["ækˈsɛpt"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Achieve",
    opciones: [
      "Lograr", 
      "Fracasar", 
      "Intentar", 
      "Obtener", 
      "Perder", 
      "Conquistar"
    ],
    respuestasCorrectas: ["Lograr"],
    fonetica: ["əˈʧiv"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Act",
    opciones: [
      "Actuar", 
      "Descansar", 
      "Esperar", 
      "Reaccionar", 
      "Observar", 
      "Decidir"
    ],
    respuestasCorrectas: ["Actuar"],
    fonetica: ["ækt"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Add",
    opciones: [
      "Agregar", 
      "Quitar", 
      "Multiplicar", 
      "Sumar", 
      "Dividir", 
      "Restar"
    ],
    respuestasCorrectas: ["Agregar"],
    fonetica: ["æd"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Admit",
    opciones: [
      "Admitir", 
      "Negar", 
      "Ocultar", 
      "Reconocer", 
      "Rechazar", 
      "Aceptar"
    ],
    respuestasCorrectas: ["Admitir"],
    fonetica: ["ədˈmɪt"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Agree",
    opciones: [
      "Acordar", 
      "Discrepar", 
      "Aceptar", 
      "Rechazar", 
      "Consentir", 
      "Negar"
    ],
    respuestasCorrectas: ["Acordar"],
    fonetica: ["əˈɡri"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Allow",
    opciones: [
      "Permitir", 
      "Prohibir", 
      "Aceptar", 
      "Rechazar", 
      "Conceder", 
      "Negar"
    ],
    respuestasCorrectas: ["Permitir"],
    fonetica: ["əˈlaʊ"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Announce",
    opciones: [
      "Anunciar", 
      "Publicar", 
      "Comunicar", 
      "Declarar", 
      "Informar", 
      "Divulgar",
    ],
    respuestasCorrectas: ["Anunciar"],
    fonetica: ["əˈnaʊns"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Annoy",
    opciones: [
      "Irritar", 
      "Molestar", 
      "Fastidiar", 
      "Incomodar", 
      "Perturbar", 
      "Hostigar",
    ],
    respuestasCorrectas: ["Irritar"],
    fonetica: ["əˈnɔɪ"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Answer",
    opciones: [
      "Contestar", 
      "Responder", 
      "Replicar", 
      "Rebatir", 
      "Satisfacer", 
      "Solucionar"
    ],
    respuestasCorrectas: ["Contestar"],
    fonetica: ["ˈænsər"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Appeal",
    opciones: [
      "Atraer", 
      "Apelar", 
      "Solicitar", 
      "Interesar", 
      "Rogar", 
      "Pedir",
    ],
    respuestasCorrectas: ["Atraer"],
    fonetica: ["əˈpil"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Appear",
    opciones: [
      "Aparecer", 
      "Surgir", 
      "Emerger", 
      "Presentarse", 
      "Manifestarse", 
      "Mostrar"
    ],
    respuestasCorrectas: ["Aparecer"],
    fonetica: ["əˈpɪr"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Argue",
    opciones: [
      "Discutir", 
      "Debatir", 
      "Rebatir", 
      "Pelear", 
      "Contender", 
      "Cuestionar",
    ],
    respuestasCorrectas: ["Discutir"],
    fonetica: ["ˈɑrɡju"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Arrange",
    opciones: [
    "Arreglar/Ordenar", 
    "Organizar", 
    "Disponer", 
    "Planificar", 
    "Preparar", 
    "Colocar",
    ],
    respuestasCorrectas: ["Arreglar/Ordenar"],
    fonetica: ["əˈreɪnʤ"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Arrive",
    opciones: [
      "Llegar", 
      "Aparecer", 
      "Alcanzar", 
      "Aterrizar", 
      "Presentarse", 
      "Desembarcar",
    ],
    respuestasCorrectas: ["Llegar"],
    fonetica: ["əˈraɪv"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Ask",
    opciones: [
      "Preguntar", 
      "Interrogar", 
      "Cuestionar", 
      "Inquirir", 
      "Solicitar", 
      "Consultar",
    ],
    respuestasCorrectas: ["Preguntar"],
    fonetica: ["æsk"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Assume",
    opciones: [
      "Asumir", 
      "Suponer", 
      "Aceptar", 
      "Presumir", 
      "Adoptar", 
      "Tomar",
    ],
    respuestasCorrectas: ["Asumir"],
    fonetica: ["əˈsum"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Attack",
    opciones: [
      "Atacar", 
      "Agredir", 
      "Asaltar", 
      "Emboscar", 
      "Ofensiva", 
      "Investir",
    ],
    respuestasCorrectas: ["Atacar"],
    fonetica: ["əˈtæk"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Attend",
    opciones: [
      "Asistir a", 
      "Participar en", 
      "Ir a", 
      "Acudir a", 
      "Presenciar", 
      "Estar presente en",
    ],
    respuestasCorrectas: ["Asistir a"],
    fonetica: ["əˈtɛnd"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Avoid",
    opciones: [
      "Evitar", 
      "Eludir", 
      "Esquivar", 
      "Rehuir", 
      "Sortear", 
      "Fugar",
    ],
    respuestasCorrectas: ["Evitar"],
    fonetica: ["əˈvɔɪd"],
  },
];