import React from "react";
import { EstructuraCuestionarioP3 } from "data/setUpQuizzesP3/questionnaireStructureP3.js";
import { preguntasIniciales } from "../wordsAnimals/l6.js";
import "verbs/lessons.css"

const tituloLeccion = "Lección 6 P3: Animals";
const linkSiguiente = "/vocabulary/animals/leccion-7";



function Leccion6AnimalsP3() {
  return (
    <EstructuraCuestionarioP3
      tituloLeccion={tituloLeccion}
      linkSiguiente={linkSiguiente}
      preguntasIniciales={preguntasIniciales}
    />
  );
}

export default Leccion6AnimalsP3;
