export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Baker",
    opciones: [
      "Panadero", 
      "Carnicero", 
      "Chef", 
      "Cocinero", 
      "Pastelero", 
      "Repostero",
    ],
    respuestasCorrectas: ["Panadero"],
    fonetica: ["ˈbeɪkər"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Businessman",
    opciones: [
      "Empresario", 
      "Contador", 
      "Abogado", 
      "Consultor", 
      "Ejecutivo", 
      "Gerente",
    ],
    respuestasCorrectas: ["Empresario"],
    fonetica: ["ˈbɪznəˌsmæn"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Businesswoman",
    opciones: [
      "Empresaria", 
      "Secretaria", 
      "Contadora", 
      "Abogada", 
      "Consultora", 
      "Gerenta",
    ],
    respuestasCorrectas: ["Empresaria"],
    fonetica: ["ˈbɪznɪˌswʊmən"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Caretaker",
    opciones: [
      "Portero o conserje", 
      "Niñero", 
      "Cuidador", 
      "Encargado", 
      "Guardia", 
      "Jardinero",
    ],
    respuestasCorrectas: ["Portero o conserje"],
    fonetica: ["ˈkɛrˌteɪkər"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Cashier",
    opciones: [
      "Cajero", 
      "Vendedor", 
      "Dependiente", 
      "Cobrador", 
      "Asistente de ventas", 
      "Reponedor",
    ],
    respuestasCorrectas: ["Cajero"],
    fonetica: ["kæˈʃɪr"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Cook",
    opciones: [
      "Cocinero", 
      "Chef", 
      "Panadero", 
      "Pastelero", 
      "Repostero", 
      "Mesero",
    ],
    respuestasCorrectas: ["Cocinero"],
    fonetica: ["kʊk"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Dressmaker",
    opciones: [
      "Modista", 
      "Sastre", 
      "Diseñador", 
      "Costurero", 
      "Confeccionista", 
      "Coser",
    ],
    respuestasCorrectas: ["Modista"],
    fonetica: ["ˈdrɛˌsmeɪkər"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Florist",
    opciones: [
      "Florista", 
      "Jardinero", 
      "Viverista", 
      "Cultivador", 
      "Paisajista", 
      "Botánico",
    ],
    respuestasCorrectas: ["Florista"],
    fonetica: ["ˈflɑrɪst"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Philosopher",
    opciones: [
      "Filósofo", 
      "Pensador", 
      "Académico", 
      "Teólogo", 
      "Escritor", 
      "Historiador",
    ],
    respuestasCorrectas: ["Filósofo"],
    fonetica: ["fəˈlɑsəfər"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Priest",
    opciones: [
      "Cura", 
      "Sacerdote", 
      "Pastor", 
      "Padre", 
      "Clérigo", 
      "Ministro",
    ],
    respuestasCorrectas: ["Cura"],
    fonetica: ["prist"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Model",
    opciones: [
      "Modelo", 
      "Actor", 
      "Cantante", 
      "Bailarín", 
      "Fotógrafo", 
      "Diseñador",
    ],
    respuestasCorrectas: ["Modelo"],
    fonetica: ["ˈmɑdəl"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Journalist",
    opciones: [
      "Periodista", 
      "Reportero", 
      "Editor", 
      "Escritor", 
      "Comentarista", 
      "Corresponsal",
    ],
    respuestasCorrectas: ["Periodista"],
    fonetica: ["ˈʤɜrnələst"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Librarian",
    opciones: [
      "Bibliotecario", 
      "Archivista", 
      "Documentalista", 
      "Asistente de biblioteca", 
      "Conservador", 
      "Investigador",
    ],
    respuestasCorrectas: ["Bibliotecario"],
    fonetica: ["laɪˈbrɛriən"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Life guard",
    opciones: [
      "Socorrista", 
      "Salvavidas", 
      "Guardavidas", 
      "Monitor de natación", 
      "Instructor de buceo", 
      "Rescatista",
    ],
    respuestasCorrectas: ["Socorrista"],
    fonetica: ["laɪf ɡɑrd"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Lorry driver",
    opciones: [
      "Camionero", 
      "Conductor de camión", 
      "Transportista", 
      "Chofer", 
      "Repartidor", 
      "Operador de vehículos pesados",
    ],
    respuestasCorrectas: ["Camionero"],
    fonetica: ["ˈlɔri ˈdraɪvər"],
  },
  {
    id: 16,
    texto: '',
    palabra: "truck driver",
    opciones: [
      "Camionero", 
      "Transportista", 
      "Chofer", 
      "Conductor de camiones", 
      "Repartidor", 
      "Operador de vehículos pesados",
    ],
    respuestasCorrectas: ["Camionero"],
    fonetica: ["trʌk ˈdraɪvər"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Nanny",
    opciones: [
      "Niñera", 
      "Cuidadora", 
      "Auxiliar de guardería", 
      "Ayudante de jardín infantil", 
      "Canguro", 
      "Guardián infantil",
    ],
    respuestasCorrectas: ["Niñera"],
    fonetica: ["ˈnæni"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Office worker",
    opciones: [
      "Oficinista", 
      "Empleado de oficina", 
      "Asistente administrativo", 
      "Secretario", 
      "Recepcionista", 
      "Auxiliar administrativo",
    ],
    respuestasCorrectas: ["Oficinista"],
    fonetica: ["ˈɔfəs ˈwɜrkər"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Pastry cook",
    opciones: [
      "Pastelero o repostero", 
      "Cocinero", 
      "Panadero", 
      "Chef de pastelería", 
      "Confitero", 
      "Repostero",
    ],
    respuestasCorrectas: ["Pastelero o repostero"],
    fonetica: ["ˈpeɪstri kʊk"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Porter",
    opciones: [
      "Portero", 
      "Conserje", 
      "Recepcionista", 
      "Vigilante", 
      "Guarda", 
      "Guardia de seguridad",
    ],
    respuestasCorrectas: ["Portero"],
    fonetica: ["ˈpɔrtər"],
  },
];