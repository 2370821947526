import React from "react";
import { Link } from "react-router-dom";
import "./home.css";

function Home() {
  return (
    <div className="home-container">
      <div className="cards-container">
        {/* Tarjeta de Verbos */}
        <Link to="/verbs" className="card"
        onClick={() => window.scrollTo(0, 0)}>
          <img
            src="/img/verbs.jpg"
            alt="Verbos"
            className="card-image"
          />
          <div className="card-content">
            <h2 className="card-title">VERBS</h2>
            <p className="card-description">
              Aprende y practica los verbos más comunes con ejercicios interactivos.
            </p>
          </div>
        </Link>

        {/* Tarjeta de Vocabulario */}
        <Link to="/vocabulary" className="card"
        onClick={() => window.scrollTo(0, 0)}>
          <img
            src="/img/vocabulary.jpg" // Reemplaza con la URL de la imagen deseada
            alt="Vocabulario"
            className="card-image"
          />
          <div className="card-content">
            <h2 className="card-title">VOCABULARY</h2>
            <p className="card-description">
              Incrementa tu vocabulario con lecciones y actividades prácticas.
            </p>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Home;
